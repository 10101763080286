import { useState, useEffect, useRef } from "react"
import * as React from "react"
import { EditProfileProps, EditPhotoButton } from "./MobileMyProfilePageContent"
import { useLanguageState } from "../../globalStates/LanguageState"
import useWindowDimensions from "../../ui/WindowDimensionsHook"
import {
    PersonImageAndLogo,
    UserBasicInfoContainer,
    UserImageDiv,
    ScrollContainerRoot,
    ProfileDetailRowHeader,
    ProfileSocialsTitle,
    ProfilePageSectionTitle,
    ProfileDetailNameTeaserCol,
    CategoriesItem,
    CategoriesViewRow,
    DummyDiv,
    CustomRow,
    DeleteProfileLink
} from "../../ui/ProfilePageStyledComponents"
import { AvatarWithDefault, encodePictureUrl } from "../../ui/AvatarWithDefault"
import { useLoggedInState, User } from "../../globalStates/LoggedInUser"
import { Row, Col } from "react-bootstrap"
import { useAppState } from "../../globalStates/AppState"
import { useHistory } from "react-router-dom"
import branding from "../../branding/branding"
import { Category, UserAnswer, UserAnswerOption, UserQuestion } from "../../backendServices/Types"
import { updateUserValues } from "../../backendServices/GraphQLServices"
import { defaultLogger as logger } from "../../globalStates/AppState"
import {
    CategoryType,
    Field,
    SocialMediaType,
    EditTab,
    BasicInfoLanguage,
    Salutation,
    SocialMediaBaseLink
} from "./MyProfilePageContentBranding"
import { myProfilePageRoute } from "../../navigationArea/RoutePaths"
import { MyProfilePageMode } from "./MyProfilePageContent"
import { ContentScrollContainer } from "../../ui/ScrollContainer"
import NetworkingToggle from "../../communicationArea/components/NetworkingToggle"
import { DoNotDisturbToggle } from "../../communicationArea/components/DoNotDisturbToggle"
import styled, { css } from "styled-components"
import CrsTabs from "../../ui/CrsTabs"
import { TextField, MultiLineTextField } from "../../ui/TextField"
import {
    IconFacebook,
    IconTwitter,
    IconLinkedIn,
    IconXing,
    IconInstagram,
    IconYouTube,
    IconPinterest,
    IconSearchSmall,
    IconIndicatorErrorBlack,
    IconFacebookOriginal,
    IconTwitterOriginal,
    IconLinkedInOriginal,
    IconXingOriginal,
    IconInstagramOriginal,
    IconYouTubeOriginal,
    IconPinterestOrginal,
    IconCloseNotification,
    IconCheckmarkSuccessfull,
    IconImageUploadPlaceholder,
    IconAnswerOptionSingleFull,
    IconAnswerOptionSingleEmpty,
    IconAnswerOptionMultipleFull,
    IconAnswerOptionMultipleEmpty
} from "../../ui/Icons"
import TextLimit from "../../ui/TextLimit"
import { getCountries, findCountryName } from "./CountrySwitcherHelper"
import Select, { ValueType, OptionTypeBase, ActionMeta } from "react-select"
import { getLanguages, findLanguageName } from "./LanguageSwitcherHelper"
import { getTimezones, findTimezoneName } from "../../utils/DateUtils"
import moment from "moment"
import DataUpdateAlert from "../../ui/DataUpdateAlert"
import CenteredLoader from "../../ui/CenteredLoader"
import { BackendServiceError } from "../../backendServices/BackendServicesUtils"
import {
    getInterest,
    updateMyProfileData,
    deleteProfileImage,
    changeProfileImage,
    deleteUserProfile,
    getSelfDefinedUserRolesByTopic,
    SelfDefinedUserRole,
    getUserQuestions
} from "../../backendServices/SeriesOfTopicsUserServices"
import ConfirmationModal from "../../ui/modals/ConfirmationModal"
import RadioField from "../../ui/Radio"
import { device } from "../../utils/Device"

interface CategoryData {
    interests: Category[]
    offerings: Category[]
    lookingfor: Category[]

    selectedInterests: Category[]
    selectedOfferings: Category[]
    selectedLookingfor: Category[]
}

const TabContentContainer = styled.div`
    margin-left: 25px;
    width: calc(100% - 25px);
`

const DisplayProfileLangLabel = styled.div`
    font-family: ${branding.font1};
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
    margin-top: 12px;
    margin-right: 30px;
    display: inline-block;

    @media ${device.mobile} {
        font-size: 14px;
        margin-bottom: 10px;
    }
`

export const RadioFieldsContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    font-family: ${branding.font1};
    font-size: 16px;
    line-height: 16px;

    @media ${device.mobile} {
        font-size: 14px;
    }
`

const DisplayProfileLangSelect = styled(Select)`
    width: 190px;
    font-family: ${branding.font1};
    color: ${branding.mainInfoColor};
    font-size: 12px;
    min-height: 35px;
    height: auto;
    display: inline-block;

    &.emailLang {
        width: 270px;
    }

    &.emailLangMobile {
        width: calc(100% - 15px);
    }
`

const SaveLoader = styled(CenteredLoader)`
    height: 35px;
    margin-right: 350px;
`

const SaveCancelContainer = styled.div<{ ccOpen: boolean; guestBannerHeight: number }>`
    top: calc(70px + ${(props) => props.guestBannerHeight + "px"});
    position: fixed;
    right: ${(props) => (props.ccOpen ? "350px" : "80px")};
    display: flex;
    gap: 10px;
    z-index: 10001;
`

export function areSocialMediaLinksCorrect(user?: User): boolean {
    if (user?.facebook?.length! > 0 && !isSocialMediaLinkCorrect(user?.facebook!, SocialMediaType.FACEBOOK)) {
        return false
    }

    if (user?.twitter?.length! > 0 && !isSocialMediaLinkCorrect(user?.twitter!, SocialMediaType.TWITTER)) {
        return false
    }

    if (user?.linkedIn?.length! > 0 && !isSocialMediaLinkCorrect(user?.linkedIn!, SocialMediaType.LINKEDIN)) {
        return false
    }

    if (user?.xing?.length! > 0 && !isSocialMediaLinkCorrect(user?.xing!, SocialMediaType.XING)) {
        return false
    }

    if (user?.instagram?.length! > 0 && !isSocialMediaLinkCorrect(user?.instagram!, SocialMediaType.INSTAGRAM)) {
        return false
    }

    if (user?.youTube?.length! > 0 && !isSocialMediaLinkCorrect(user?.youTube!, SocialMediaType.YOUTUBE)) {
        return false
    }

    if (user?.pinterest?.length! > 0 && !isSocialMediaLinkCorrect(user?.pinterest!, SocialMediaType.PINTEREST)) {
        return false
    }
    return true
}

export function areRequiredFieldsEmpty(user: User, fieldsList: Field[], basicInfoLang: string): boolean {
    let emptyRequiredFieldsExist: boolean = false

    fieldsList.forEach((field: Field) => {
        if (field === Field.SALUTATION) {
            if (
                (basicInfoLang === "en" && isRequiredAndEmpty(field, user?.salutation)) ||
                (basicInfoLang === "de" && isRequiredAndEmpty(field, user?.salutationDe))
            ) {
                emptyRequiredFieldsExist = true
            }
        } else if (field === Field.FIRSTNAME) {
            if (isRequiredAndEmpty(field, user?.firstName, true)) {
                emptyRequiredFieldsExist = true
            }
        } else if (field === Field.LASTNAME) {
            if (isRequiredAndEmpty(field, user?.lastName, true)) {
                emptyRequiredFieldsExist = true
            }
        } else if (field === Field.POSITION) {
            if (
                !checkRequirementOverrideForPrivatePersons(user) &&
                ((basicInfoLang === "en" && isRequiredAndEmpty(field, user?.position)) ||
                    (basicInfoLang === "de" && isRequiredAndEmpty(field, user?.positionDe)))
            ) {
                emptyRequiredFieldsExist = true
            }
        } else if (field === Field.COMPANY) {
            if (!checkRequirementOverrideForPrivatePersons(user) && isRequiredAndEmpty(field, user?.company)) {
                emptyRequiredFieldsExist = true
            }
        } else if (field === Field.INDUSTRY) {
            if (
                (basicInfoLang === "en" && isRequiredAndEmpty(field, user?.industry)) ||
                (basicInfoLang === "de" && isRequiredAndEmpty(field, user?.industryDe))
            ) {
                emptyRequiredFieldsExist = true
            }
        } else if (field === Field.COUNTRY) {
            if (isRequiredAndEmpty(field, user?.countrycode)) {
                emptyRequiredFieldsExist = true
            }
        } else if (field === Field.BIOGRAPHY) {
            if (
                (basicInfoLang === "en" && isRequiredAndEmpty(field, user?.infotext)) ||
                (basicInfoLang === "de" && isRequiredAndEmpty(field, user?.infotextDe))
            ) {
                emptyRequiredFieldsExist = true
            }
        } else if (field === Field.PHONE) {
            if (isRequiredAndEmpty(field, user?.phone)) {
                emptyRequiredFieldsExist = true
            }
        } else if (field === Field.MOBILE) {
            if (isRequiredAndEmpty(field, user?.mobile)) {
                emptyRequiredFieldsExist = true
            }
        } else if (field === Field.ADRESS1) {
            if (isRequiredAndEmpty(field, user?.adress1)) {
                emptyRequiredFieldsExist = true
            }
        } else if (field === Field.ADRESS2) {
            if (isRequiredAndEmpty(field, user?.adress2)) {
                emptyRequiredFieldsExist = true
            }
        } else if (field === Field.ADRESS3) {
            if (isRequiredAndEmpty(field, user?.adress3)) {
                emptyRequiredFieldsExist = true
            }
        } else if (field === Field.CITY) {
            if (isRequiredAndEmpty(field, user?.city)) {
                emptyRequiredFieldsExist = true
            }
        } else if (field === Field.POSTAL_CODE) {
            if (isRequiredAndEmpty(field, user?.postalCode)) {
                emptyRequiredFieldsExist = true
            }
        } else if (field === Field.LANGUAGE) {
            if (isRequiredAndEmpty(field, user?.languages)) {
                emptyRequiredFieldsExist = true
            }
        } else if (field === Field.USER_ROLE) {
            if (isRequiredAndEmpty(field, user?.selfDefinedUserRoleId)) {
                emptyRequiredFieldsExist = true
            }
        } else if (field === Field.FACEBOOK) {
            if (isRequiredAndEmpty(field, user?.facebook)) {
                emptyRequiredFieldsExist = true
            }
        } else if (field === Field.TWITTER) {
            if (isRequiredAndEmpty(field, user?.twitter)) {
                emptyRequiredFieldsExist = true
            }
        } else if (field === Field.INSTAGRAM) {
            if (isRequiredAndEmpty(field, user?.instagram)) {
                emptyRequiredFieldsExist = true
            }
        } else if (field === Field.YOUTUBE) {
            if (isRequiredAndEmpty(field, user?.youTube)) {
                emptyRequiredFieldsExist = true
            }
        } else if (field === Field.LINKEDIN) {
            if (isRequiredAndEmpty(field, user?.linkedIn)) {
                emptyRequiredFieldsExist = true
            }
        } else if (field === Field.XING) {
            if (isRequiredAndEmpty(field, user?.xing)) {
                emptyRequiredFieldsExist = true
            }
        } else if (field === Field.PINTEREST) {
            if (isRequiredAndEmpty(field, user?.pinterest)) {
                emptyRequiredFieldsExist = true
            }
        }
    })

    return emptyRequiredFieldsExist
}

export function areAllFieldsEmpty(user: User, fieldsList: Field[], basicInfoLang: string): boolean {
    let numberOfEmptyFields: number = 0
    let fieldsListLength: number = fieldsList.length

    if (fieldsList.includes(Field.TIMEZONE)) {
        fieldsListLength -= 1
    }

    fieldsList.forEach((field: Field) => {
        if (field === Field.SALUTATION) {
            if (
                (basicInfoLang === "en" && isEmpty(user?.salutationDe)) ||
                (basicInfoLang === "de" && isEmpty(user?.salutationDe))
            ) {
                numberOfEmptyFields += 1
            }
        } else if (field === Field.LOGO) {
            if (isEmpty(user?.logoUrl)) {
                numberOfEmptyFields += 1
            }
        } else if (field === Field.FIRSTNAME) {
            if (isEmpty(user?.firstName)) {
                numberOfEmptyFields += 1
            }
        } else if (field === Field.LASTNAME) {
            if (isEmpty(user?.lastName)) {
                numberOfEmptyFields += 1
            }
        } else if (field === Field.POSITION) {
            if ((basicInfoLang === "en" && isEmpty(user?.position)) || (basicInfoLang === "de" && isEmpty(user?.positionDe))) {
                numberOfEmptyFields += 1
            }
        } else if (field === Field.COMPANY) {
            if (isEmpty(user?.company)) {
                numberOfEmptyFields += 1
            }
        } else if (field === Field.INDUSTRY) {
            if ((basicInfoLang === "en" && isEmpty(user?.industry)) || (basicInfoLang === "de" && isEmpty(user?.industryDe))) {
                numberOfEmptyFields += 1
            }
        } else if (field === Field.COUNTRY) {
            if (isEmpty(user?.countrycode)) {
                numberOfEmptyFields += 1
            }
        } else if (field === Field.BIOGRAPHY) {
            if ((basicInfoLang === "en" && isEmpty(user?.infotext)) || (basicInfoLang === "de" && isEmpty(user?.infotextDe))) {
                numberOfEmptyFields += 1
            }
        } else if (field === Field.PHONE) {
            if (isEmpty(user?.phone)) {
                numberOfEmptyFields += 1
            }
        } else if (field === Field.MOBILE) {
            if (isEmpty(user?.mobile)) {
                numberOfEmptyFields += 1
            }
        } else if (field === Field.ADRESS1) {
            if (isEmpty(user?.adress1)) {
                numberOfEmptyFields += 1
            }
        } else if (field === Field.ADRESS2) {
            if (isEmpty(user?.adress2)) {
                numberOfEmptyFields += 1
            }
        } else if (field === Field.ADRESS3) {
            if (isEmpty(user?.adress3)) {
                numberOfEmptyFields += 1
            }
        } else if (field === Field.CITY) {
            if (isEmpty(user?.city)) {
                numberOfEmptyFields += 1
            }
        } else if (field === Field.POSTAL_CODE) {
            if (isEmpty(user?.postalCode)) {
                numberOfEmptyFields += 1
            }
        } else if (field === Field.LANGUAGE) {
            if (isEmpty(user?.languages)) {
                numberOfEmptyFields += 1
            }
        } else if (field === Field.FACEBOOK) {
            if (isEmpty(user?.facebook)) {
                numberOfEmptyFields += 1
            }
        } else if (field === Field.TWITTER) {
            if (isEmpty(user?.twitter)) {
                numberOfEmptyFields += 1
            }
        } else if (field === Field.INSTAGRAM) {
            if (isEmpty(user?.instagram)) {
                numberOfEmptyFields += 1
            }
        } else if (field === Field.YOUTUBE) {
            if (isEmpty(user?.youTube)) {
                numberOfEmptyFields += 1
            }
        } else if (field === Field.LINKEDIN) {
            if (isEmpty(user?.linkedIn)) {
                numberOfEmptyFields += 1
            }
        } else if (field === Field.XING) {
            if (isEmpty(user?.xing)) {
                numberOfEmptyFields += 1
            }
        } else if (field === Field.PINTEREST) {
            if (isEmpty(user?.pinterest)) {
                numberOfEmptyFields += 1
            }
        }
    })

    return numberOfEmptyFields === fieldsListLength
}

export function isAttributesSelectionEmpty(user: User, questions: UserQuestion[]) {
    for (const question of questions) {
        if (user.answers && user.answers![question.id as any]) {
            if (user.answers![question.id as any]?.length! > 0) {
                return false
            }
        }
    }
    return true
}

export function areRequiredInterestsEmpty(categoryData: CategoryData) {
    if (
        branding.myProfilePageContent.requiredInterestsNumber > 0 &&
        categoryData.selectedInterests.length < branding.myProfilePageContent.requiredInterestsNumber
    )
        return true
    else if (branding.myProfilePageContent.requiredInterestsNumber === 0) return false

    return false
}

export function isCategorySelectionEmpty(categoryData: CategoryData, type: CategoryType) {
    if (type === CategoryType.INTERESTS && categoryData.selectedInterests.length === 0) {
        return true
    } else if (type === CategoryType.OFFERS && categoryData.selectedOfferings.length === 0) {
        return true
    } else if (type === CategoryType.NEEDS && categoryData.selectedLookingfor.length === 0) {
        return true
    }

    return false
}

export function getAttributesValidationErrors(user: User, questions: UserQuestion[]): boolean {
    for (const question of questions) {
        if (user.answers && user.answers![question.id as any]) {
            if (question.minAnswerCount > 0 && user.answers![question.id as any]?.length! < question.minAnswerCount) {
                return true
            } else if (
                question.minAnswerCount === 1 &&
                question.maxAnswerCount === 1 &&
                user.answers![question.id as any]?.length! < 1
            ) {
                return true
            }
        } else {
            if (question.minAnswerCount > 0 && user.answers![question.id as any] === undefined) {
                return true
            }
        }
    }
    return false
}

export function phoneNumbersValidationCorrect(user: User, fieldsList: Field[]) {
    if (fieldsList.includes(Field.PHONE) && !isPhoneNumberCorrect(user?.phone || "", Field.PHONE)) {
        return false
    } else if (fieldsList.includes(Field.MOBILE) && !isPhoneNumberCorrect(user?.mobile || "", Field.MOBILE)) {
        return false
    }

    return true
}

export function isPhoneNumberCorrect(phoneNumber: string, field: Field): boolean {
    if (!isInFieldsList(field)) {
        return true
    }

    if (phoneNumber && phoneNumber?.length! > 0) {
        // eslint-disable-next-line
        const phoneNumberRegex: RegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g
        return phoneNumberRegex.test(phoneNumber.trim())
    }
    return true
}

export function isInFieldsList(field: Field) {
    const fieldsList = branding.myProfilePageContent.editProfileFieldsList

    if (fieldsList.find((e: Field) => e === field)) {
        return true
    }

    return false
}

const EmailContainer = styled.div`
    color: ${branding.sideIconBar.sideIconColorDark};
    font-size: 16px;
    line-height: 17px;

    @media ${device.mobile} {
        font-size: 14px;
    }
`

interface SectionProps {
    user?: User
    setUser: (value: User) => void
    basicInfoLang: string
    setBasicInfoLang: (value: string) => void
    userRegistrationPage?: boolean
    showLanguageSelector?: boolean
    fieldsList?: Field[]

    userRoles?: SelfDefinedUserRole[]

    setImageDimensions?: (values: any) => void

    setImageSize?: (value: number) => void
    setImageFile?: (value: any) => void

    questions?: UserQuestion[]
}

function checkRequirementOverrideForPrivatePersons(user?: User) {
    return (
        user?.selfDefinedUserRoleId !== null &&
        user?.selfDefinedUserRoleId !== undefined &&
        user?.selfDefinedUserRoleId! === branding.myProfilePageContent.privatePersonUserRoleId
    )
}

export function getEditFields(
    fieldsList: Field[],
    user: User,
    setUser: (value: User) => void,
    strings: any,
    basicInfoLang: string,
    userRoles: SelfDefinedUserRole[],
    showRequiredFieldWarning?: boolean //this property is here because required field warnings were not in the design for user registration page
) {
    return (
        <>
            {fieldsList.map((item, index) => {
                switch (item) {
                    case Field.SALUTATION: {
                        return (
                            <React.Fragment key={item}>
                                <SalutationSwitcher
                                    user={user!}
                                    updateValues={setUser}
                                    showRequiredFieldWarning={showRequiredFieldWarning}
                                />
                            </React.Fragment>
                        )
                    }

                    case Field.FIRSTNAME: {
                        return (
                            <ProfileInformation
                                key={item}
                                required={true}
                                labelText={strings.myProfilePageContent.nameLabel}
                                placeholder={strings.myProfilePageContent.namePlaceholder}
                                value={user?.firstName || ""}
                                updateValue={(value: string) => setUser({ ...user!, firstName: value })}
                                fieldMarker={Field.FIRSTNAME}
                                showRequiredFieldWarning={showRequiredFieldWarning}
                            />
                        )
                    }

                    case Field.LASTNAME: {
                        return (
                            <ProfileInformation
                                key={item}
                                required={true}
                                labelText={strings.myProfilePageContent.surnameLabel}
                                placeholder={strings.myProfilePageContent.surnamePlaceholder}
                                value={user?.lastName || ""}
                                updateValue={(value: string) => setUser({ ...user!, lastName: value })}
                                fieldMarker={Field.LASTNAME}
                                showRequiredFieldWarning={showRequiredFieldWarning}
                            />
                        )
                    }

                    case Field.POSITION: {
                        return (
                            <ProfileInformation
                                key={item}
                                labelText={
                                    basicInfoLang === "en"
                                        ? strings.myProfilePageContent.jobTitleLabel
                                        : strings.myProfilePageContent.jobTitleDeLabel
                                }
                                placeholder={
                                    basicInfoLang === "en"
                                        ? strings.myProfilePageContent.jobTitlePlaceholder
                                        : strings.myProfilePageContent.jobTitleDePlaceholder
                                }
                                value={(basicInfoLang === "en" ? user?.position : user?.positionDe) || ""}
                                updateValue={(value: string) =>
                                    setUser(
                                        basicInfoLang === "en" ? { ...user!, position: value } : { ...user!, positionDe: value }
                                    )
                                }
                                fieldMarker={Field.POSITION}
                                showRequiredFieldWarning={showRequiredFieldWarning}
                                requirementOverrideForPrivatePersons={checkRequirementOverrideForPrivatePersons(user)}
                            />
                        )
                    }

                    case Field.INDUSTRY: {
                        return (
                            <ProfileInformation
                                key={item}
                                labelText={
                                    basicInfoLang === "en"
                                        ? strings.myProfilePageContent.industryLabel
                                        : strings.myProfilePageContent.industryDeLabel
                                }
                                placeholder={
                                    basicInfoLang === "en"
                                        ? strings.myProfilePageContent.industryPlaceholder
                                        : strings.myProfilePageContent.industryDePlaceholder
                                }
                                value={(basicInfoLang === "en" ? user?.industry : user?.industryDe) || ""}
                                updateValue={(value: string) =>
                                    setUser(
                                        basicInfoLang === "en" ? { ...user!, industry: value } : { ...user!, industryDe: value }
                                    )
                                }
                                fieldMarker={Field.INDUSTRY}
                                showRequiredFieldWarning={showRequiredFieldWarning}
                            />
                        )
                    }

                    case Field.COMPANY: {
                        return (
                            <ProfileInformation
                                key={item}
                                labelText={strings.myProfilePageContent.companyLabel}
                                placeholder={strings.myProfilePageContent.companyPlaceholder}
                                value={user?.company || ""}
                                updateValue={(value: string) => setUser({ ...user!, company: value })}
                                fieldMarker={Field.COMPANY}
                                showRequiredFieldWarning={showRequiredFieldWarning}
                                requirementOverrideForPrivatePersons={checkRequirementOverrideForPrivatePersons(user)}
                            />
                        )
                    }

                    case Field.POSTAL_CODE: {
                        return (
                            <ProfileInformation
                                key={item}
                                labelText={strings.myProfilePageContent.postalCodePlaceholder}
                                placeholder={strings.myProfilePageContent.postalCodePlaceholder}
                                value={user?.postalCode || ""}
                                updateValue={(value: string) => setUser({ ...user!, postalCode: value })}
                                fieldMarker={Field.POSTAL_CODE}
                                showRequiredFieldWarning={showRequiredFieldWarning}
                            />
                        )
                    }

                    case Field.COUNTRY: {
                        return (
                            <CountrySwitcher
                                key={item}
                                country={user?.countrycode || ""}
                                setCountry={(value: string) => setUser({ ...user!, countrycode: value })}
                                showRequiredFieldWarning={showRequiredFieldWarning}
                            />
                        )
                    }

                    case Field.LANGUAGE: {
                        return (
                            <LanguageSwitcher
                                key={item}
                                languages={user?.languages || []}
                                setLanguages={(value: any[]) => setUser({ ...user!, languages: value })}
                                showRequiredFieldWarning={showRequiredFieldWarning}
                            />
                        )
                    }

                    case Field.USER_ROLE: {
                        if (userRoles.length === 0) return null

                        return (
                            <React.Fragment key={item}>
                                <UserRoleSwitcher
                                    user={user!}
                                    userRoles={userRoles}
                                    updateValues={setUser}
                                    showRequiredFieldWarning={showRequiredFieldWarning}
                                />
                            </React.Fragment>
                        )
                    }

                    case Field.TIMEZONE: {
                        return <TimeZoneSwitcher key={item} />
                    }

                    case Field.PHONE: {
                        return (
                            <ProfileInformation
                                key={item}
                                labelText={strings.myProfilePageContent.phoneLabel}
                                placeholder={strings.myProfilePageContent.phonePlaceholder}
                                value={user?.phone || ""}
                                updateValue={(value: string) => setUser({ ...user!, phone: value })}
                                fieldMarker={Field.PHONE}
                                validation={isPhoneNumberCorrect}
                                validationWarning={strings.myProfilePageContent.phoneValidationIncorrectWarning}
                                showRequiredFieldWarning={showRequiredFieldWarning}
                            />
                        )
                    }

                    case Field.MOBILE: {
                        return (
                            <ProfileInformation
                                key={item}
                                labelText={strings.myProfilePageContent.mobileLabel}
                                placeholder={strings.myProfilePageContent.mobilePlaceholder}
                                value={user?.mobile || ""}
                                updateValue={(value: string) => setUser({ ...user!, mobile: value })}
                                validation={isPhoneNumberCorrect}
                                fieldMarker={Field.MOBILE}
                                validationWarning={strings.myProfilePageContent.phoneValidationIncorrectWarning}
                                showRequiredFieldWarning={showRequiredFieldWarning}
                            />
                        )
                    }

                    case Field.ADRESS1: {
                        return (
                            <ProfileInformation
                                key={item}
                                labelText={strings.myProfilePageContent.adress1Label}
                                placeholder={strings.myProfilePageContent.adress1Placeholder}
                                value={user?.adress1 || ""}
                                updateValue={(value: string) => setUser({ ...user!, adress1: value })}
                                fieldMarker={Field.ADRESS1}
                                showRequiredFieldWarning={showRequiredFieldWarning}
                            />
                        )
                    }

                    case Field.ADRESS2: {
                        return (
                            <ProfileInformation
                                key={item}
                                labelText={strings.myProfilePageContent.adress2Label}
                                placeholder={strings.myProfilePageContent.adress2Placeholder}
                                value={user?.adress2 || ""}
                                updateValue={(value: string) => setUser({ ...user!, adress2: value })}
                                fieldMarker={Field.ADRESS2}
                                showRequiredFieldWarning={showRequiredFieldWarning}
                            />
                        )
                    }

                    case Field.ADRESS3: {
                        return (
                            <ProfileInformation
                                key={item}
                                labelText={strings.myProfilePageContent.adress3Label}
                                placeholder={strings.myProfilePageContent.adress3Placeholder}
                                value={user?.adress3 || ""}
                                updateValue={(value: string) => setUser({ ...user!, adress3: value })}
                                fieldMarker={Field.ADRESS3}
                                showRequiredFieldWarning={showRequiredFieldWarning}
                            />
                        )
                    }

                    case Field.CITY: {
                        return (
                            <ProfileInformation
                                key={item}
                                labelText={strings.myProfilePageContent.cityLabel}
                                placeholder={strings.myProfilePageContent.cityPlaceholder}
                                value={user?.city || ""}
                                updateValue={(value: string) => setUser({ ...user!, city: value })}
                                fieldMarker={Field.CITY}
                                showRequiredFieldWarning={showRequiredFieldWarning}
                            />
                        )
                    }

                    default:
                        return null
                }
            })}
        </>
    )
}

export const BasicInfo: React.FunctionComponent<SectionProps> = React.memo((props: SectionProps) => {
    const strings = useLanguageState().getStrings()
    const fieldsList = props.fieldsList ?? branding.myProfilePageContent.editProfileFieldsList

    useEffect(() => {
        // prevent saving empty positions
        if (props.user?.position && !props.user?.positionDe) {
            props.setUser({ ...props.user!, positionDe: props.user?.position! })
        } else if (props.user?.positionDe && !props.user?.position) {
            props.setUser({ ...props.user!, position: props.user?.positionDe! })
        }

        // prevent saving empty industry data
        if (props.user?.industry && !props.user?.industryDe) {
            props.setUser({ ...props.user!, industryDe: props.user?.industry! })
        } else if (props.user?.industryDe && !props.user?.industry) {
            props.setUser({ ...props.user!, industry: props.user?.industryDe! })
        }

        // prevent saving empty salutations
        if (props.user?.salutation && !props.user?.salutationDe) {
            props.setUser({ ...props.user!, salutationDe: props.user?.salutation! })
        } else if (props.user?.salutationDe && !props.user?.salutation) {
            props.setUser({ ...props.user!, salutation: props.user?.salutationDe! })
        }

        // eslint-disable-next-line
    }, [props.basicInfoLang])

    return (
        <EditProfileInformationField>
            {!props.showLanguageSelector ? null : props.userRegistrationPage ? (
                <>
                    {branding.userRegistrationSite.basicInfoLanguagesList.length > 1 &&
                        branding.availableLanguages.length > 1 && (
                            <Row style={{ marginLeft: "0px" }}>
                                <DisplayProfileLangLabel style={{ marginTop: "0px" }}>
                                    {strings.myProfilePageContent.displayProfileLangLabel}
                                </DisplayProfileLangLabel>
                                <RadioFieldsContainer>
                                    {branding.userRegistrationSite.basicInfoLanguagesList.map(
                                        (item: BasicInfoLanguage, index: number) => {
                                            return (
                                                <RadioField
                                                    key={index}
                                                    id={item.value}
                                                    name={item.label}
                                                    value={item.value}
                                                    radioValue={props.basicInfoLang}
                                                    label={item.label}
                                                    textColor={branding.sideIconBar.sideIconColorDark}
                                                    onChange={() => props.setBasicInfoLang(item.value)}
                                                />
                                            )
                                        }
                                    )}
                                </RadioFieldsContainer>
                            </Row>
                        )}

                    <Row style={{ marginLeft: "0px", marginTop: "30px", marginBottom: "15px" }}>
                        <EmailContainer>{props.user?.email}</EmailContainer>
                    </Row>
                </>
            ) : (
                <>
                    {branding.myProfilePageContent.basicInfoLanguagesList.length > 1 &&
                        branding.availableLanguages.length > 1 && (
                            <Row
                                style={{
                                    marginLeft: "0px",
                                    justifyContent: "space-between",
                                    marginRight: "25px",
                                    marginBottom: "50px"
                                }}
                            >
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <DisplayProfileLangLabel style={{ marginTop: "0px" }}>
                                        {strings.myProfilePageContent.displayProfileLangLabel}
                                    </DisplayProfileLangLabel>
                                    <RadioFieldsContainer>
                                        {branding.myProfilePageContent.basicInfoLanguagesList.map(
                                            (item: BasicInfoLanguage, index: number) => {
                                                return (
                                                    <RadioField
                                                        key={index}
                                                        id={item.value}
                                                        name={item.label}
                                                        value={item.value}
                                                        radioValue={props.basicInfoLang}
                                                        label={item.label}
                                                        textColor={branding.sideIconBar.sideIconColorDark}
                                                        onChange={() => props.setBasicInfoLang(item.value)}
                                                    />
                                                )
                                            }
                                        )}
                                    </RadioFieldsContainer>
                                </div>
                                <EmailContainer>{props.user?.email}</EmailContainer>
                            </Row>
                        )}
                </>
            )}

            <Row style={{ marginRight: "5px" }}>
                {getEditFields(
                    fieldsList,
                    props.user!,
                    props.setUser,
                    strings,
                    props.basicInfoLang,
                    props.userRoles || [],
                    !props.userRegistrationPage
                )}
            </Row>

            {fieldsList.includes(Field.LOGO) && props.userRegistrationPage && (
                <ProfileImage
                    user={props.user}
                    setUser={props.setUser}
                    setImageDimensions={props.setImageDimensions!}
                    setImageSize={props.setImageSize!}
                    setImageFile={props.setImageFile!}
                    userRegistrationPage
                />
            )}

            {fieldsList.includes(Field.BIOGRAPHY) && (
                <Row
                    style={{
                        marginRight: "5px",
                        marginLeft: "-13px",
                        marginTop: "25px",
                        paddingBottom: props.userRegistrationPage ? "30px" : "0px"
                    }}
                >
                    <ProfileInformation
                        key={Field.BIOGRAPHY}
                        labelText={
                            props.basicInfoLang === "en"
                                ? strings.myProfilePageContent.biographyLabel
                                : strings.myProfilePageContent.biographyDeLabel
                        }
                        placeholder={
                            props.basicInfoLang === "en"
                                ? strings.myProfilePageContent.biographyPlaceholder
                                : strings.myProfilePageContent.biographyDePlaceholder
                        }
                        value={(props.basicInfoLang === "en" ? props.user?.infotext : props.user?.infotextDe) || ""}
                        updateValue={(value: string) =>
                            props.setUser(
                                props.basicInfoLang === "en"
                                    ? { ...props.user!, infotext: value }
                                    : { ...props.user!, infotextDe: value }
                            )
                        }
                        fieldMarker={Field.BIOGRAPHY}
                        multiLine
                    />
                </Row>
            )}
        </EditProfileInformationField>
    )
})

const UserQuestionText = styled.div`
    font-family: ${branding.font1};
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    margin-bottom: 15px;
    user-select: none;
    color: ${branding.loginRegistrationSite.mobileLoginTextColor};

    @media ${device.mobile} {
        flex-direction: column;
        font-size: 14px;
    }
`

const UserQuestionWarning = styled.div`
    color: ${branding.dangerButtonColor};
    font-size: 14px;
    font-weight: normal;
    padding-left: 5px;

    @media ${device.mobile} {
        padding-left: 0px;
        font-size: 12px;
        padding-top: 10px;
    }
`

interface UserDefinedOption {
    id: string
    value: string
}

interface UserQuestionProps {
    order: number
    question: UserQuestion
    answers: UserAnswer[]
    updateAnswers: (answers: UserAnswer[]) => void
}

export const UserQuestionComponent: React.FunctionComponent<UserQuestionProps> = React.memo((props: UserQuestionProps) => {
    const strings = useLanguageState().getStrings()

    const singleChoice: boolean = props.question.maxAnswerCount === 1
    const required: boolean = props.question.minAnswerCount > 0
    const [answerOptions, setAnswerOptions] = useState<UserAnswerOption[]>([])
    const [selectedOptions, setSelectedOptions] = useState<string[]>([])
    const [userDefinedOptions, setUserDefinedOptions] = useState<UserDefinedOption[]>(getInitialUserDefinedOptions())

    //for predefined answer options

    useEffect(() => {
        let answerOptionsTemp: UserAnswerOption[] = props.question.answerOptions || []
        let selectedOptionsTemp: string[] = []
        let userDefinedOptionsTemp: UserDefinedOption[] = userDefinedOptions

        props.answers.forEach((answer: UserAnswer) => {
            //Inside user profile response, we get a value for user defined options, so we will use that to treat them as user defined options in UI
            if (answer.value) {
                let id = generateUserDefinedOptionId()
                userDefinedOptionsTemp.push({ id: id, value: answer.value! })
            } else if (answer.optionId) {
                selectedOptionsTemp.push(answer.optionId!)
            }
        })

        answerOptionsTemp = answerOptionsTemp.filter(
            (option: UserAnswerOption) => !(option.feUserDefined && !selectedOptionsTemp.includes(option.id))
        )

        setAnswerOptions(answerOptionsTemp)
        setSelectedOptions(selectedOptionsTemp)
        setUserDefinedOptions(userDefinedOptionsTemp)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (answerOptions.length === 1 && answerOptions[0]?.feUserDefined && props.question.maxAnswerCount) {
            setAnswerOptions([])
        }
        // eslint-disable-next-line
    }, [answerOptions, userDefinedOptions])

    function blockSelection(): boolean {
        return (
            props.question.maxAnswerCount > 0 &&
            !(props.question.minAnswerCount === 1 && props.question.maxAnswerCount === 1) &&
            !singleChoice &&
            selectedOptions.length + getUserDefinedOptions().length === props.question.maxAnswerCount
        )
    }

    function onOptionClick(optionId: string) {
        if (blockSelection() && !selectedOptions.includes(optionId)) {
            return
        }

        if (singleChoice) {
            if (isSelected(optionId)) {
                setSelectedOptions([])
            } else {
                setUserDefinedOptions([])
                setSelectedOptions([optionId])
            }
        } else {
            if (isSelected(optionId)) {
                setSelectedOptions(selectedOptions.filter((o) => optionId !== o))
            } else {
                if (
                    props.question.maxAnswerCount === 0 ||
                    selectedOptions.length + getUserDefinedOptions().length < props.question.maxAnswerCount
                ) {
                    setSelectedOptions(selectedOptions.concat(optionId))
                }
            }
        }
    }

    function isSelected(optionId: any): boolean {
        return selectedOptions.includes(optionId)
    }

    //for user defined answer options

    function getInitialUserDefinedOptions() {
        //checking if there is an answer for a single custom field question

        return [{ id: generateUserDefinedOptionId(), value: "" }]
    }

    function generateUserDefinedOptionId(): string {
        return Date.now().toString() + Math.random().toString()
    }

    function getUserDefinedOptions(): UserDefinedOption[] {
        return userDefinedOptions.filter((o) => o.value.length > 0)
    }

    function editUserDefinedOptions(option: UserDefinedOption) {
        const index = userDefinedOptions.findIndex((o) => o.id === option.id)

        if (index > -1) {
            if (singleChoice && option.value.length > 0) {
                setSelectedOptions([])
            }
            setUserDefinedOptions([option])
        } else {
            setUserDefinedOptions(userDefinedOptions.concat(option))
        }
    }

    function removeUserDefinedOption(optionId: string) {
        const index = userDefinedOptions.findIndex((o) => o.id === optionId)

        if (index > -1 && userDefinedOptions.length > 1) {
            setUserDefinedOptions(userDefinedOptions.filter((o) => o.id !== optionId))
        }
    }

    useEffect(() => {
        let answers: UserAnswer[] = []

        selectedOptions.forEach((optionId: string) => {
            answers.push({ optionId: optionId })
        })

        userDefinedOptions.forEach((option: UserDefinedOption) => {
            if (option.value.length > 0) {
                answers.push({ value: option.value })
            }
        })

        props.updateAnswers(answers)
        // eslint-disable-next-line
    }, [selectedOptions, userDefinedOptions])

    function isMinimumAmountNotSelected(): boolean {
        return required && selectedOptions.length + getUserDefinedOptions().length < props.question.minAnswerCount
    }

    return (
        <Col xs={24} md={12}>
            <UserQuestionText>
                <div style={{ display: "flex" }}>
                    {props.order}. {props.question.text} {<RequiredFieldLabel visible={required}>*</RequiredFieldLabel>}
                </div>
                {!singleChoice && isMinimumAmountNotSelected() && (
                    <UserQuestionWarning>
                        {props.question.minAnswerCount === 1
                            ? strings.myProfilePageContent.requiredAnswersWarningSingle
                            : strings.myProfilePageContent.requiredAnswersWarningMultiple.replace(
                                  "${number}",
                                  props.question.minAnswerCount.toString()
                              )}
                    </UserQuestionWarning>
                )}
            </UserQuestionText>
            {answerOptions.map((option: UserAnswerOption) => {
                return (
                    <AnswerOptionComponent
                        key={option.id}
                        id={option.id}
                        selected={isSelected(option.id)}
                        option={option}
                        singleChoice={singleChoice}
                        onOptionClick={onOptionClick}
                        blockSelection={blockSelection()}
                    />
                )
            })}
            {props.question.allowUserDefinedAnswerOption && (
                <>
                    {userDefinedOptions.map((option: UserDefinedOption) => {
                        return (
                            <AnswerOptionComponent
                                key={option.id}
                                id={option.id}
                                singleChoice={singleChoice}
                                onlyChoice={
                                    answerOptions.length === 0 &&
                                    userDefinedOptions.length === 1 &&
                                    props.question.maxAnswerCount === 1
                                }
                                onOptionClick={onOptionClick}
                                userDefined
                                userDefinedValue={option}
                                editOption={editUserDefinedOptions}
                                removeOption={removeUserDefinedOption}
                                numberOfOptions={userDefinedOptions.length}
                                blockSelection={blockSelection()}
                            />
                        )
                    })}
                </>
            )}
        </Col>
    )
})

const AnswerOptionContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 15px;
    margin-top: 15px;
    align-items: center;
    cursor: pointer;
    user-select: none;
    color: ${branding.loginRegistrationSite.mobileLoginTextColor};

    &.blockedSelection {
        cursor: not-allowed;
        pointer-events: none;
    }
`

const AnswerOptionIcon = styled.div``

const AnswerOptionText = styled.div`
    font-size: 14px;
    line-height: 16px;
    font-weight: normal;
    font-family: ${branding.font1};
    margin-top: 3px;

    @media ${device.mobile} {
        font-size: 12px;
    }
`

const TextLimitContainer = styled.div`
    width: 95%;
`

const MAX_TITLE_LENGTH = 200

interface AnswerOptionProps {
    id: string
    selected?: boolean
    option?: UserAnswerOption
    singleChoice: boolean
    onlyChoice?: boolean
    blockSelection: boolean
    onOptionClick: (optionId: string) => void
    userDefined?: boolean
    userDefinedValue?: UserDefinedOption
    numberOfOptions?: number
    editOption?: (option: UserDefinedOption) => void
    removeOption?: (optionId: string) => void
}

export const AnswerOptionComponent: React.FunctionComponent<AnswerOptionProps> = React.memo((props: AnswerOptionProps) => {
    const strings = useLanguageState().getStrings()

    const [text, setText] = useState<string>(props.userDefined ? props.userDefinedValue?.value! : "")

    useEffect(() => {
        if (props.editOption) {
            props.editOption!(getOption())
        }
        // eslint-disable-next-line
    }, [text])

    function getIcon() {
        if (props.singleChoice) {
            if (props.selected || text.length > 0) {
                return IconAnswerOptionSingleFull({ fill: branding.userRegistrationSite.selectedTabColor })
            } else {
                return IconAnswerOptionSingleEmpty({ fill: branding.sideIconBar.sideIconColorDark })
            }
        } else {
            if (props.selected || text.length > 0) {
                return IconAnswerOptionMultipleFull({ fill: branding.userRegistrationSite.selectedTabColor })
            } else {
                return IconAnswerOptionMultipleEmpty({ fill: branding.sideIconBar.sideIconColorDark })
            }
        }
    }

    function getOption(): UserDefinedOption {
        return { id: props.userDefinedValue ? props.userDefinedValue?.id! : "", value: text }
    }

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <AnswerOptionContainer
                onClick={() => {
                    if (!props.userDefined) {
                        props.onOptionClick(props.id)
                    }
                }}
            >
                {!props.onlyChoice && (
                    <AnswerOptionIcon
                        onClick={() => {
                            if (props.userDefined) {
                                const egFocusTextInput = document.getElementById(props.id)

                                if (props.numberOfOptions && props.numberOfOptions === 1) {
                                    if (text.length === 0) {
                                        if (egFocusTextInput && text.length === 0) {
                                            egFocusTextInput.focus()
                                        }
                                    } else {
                                        setText("")
                                    }
                                } else if (text.length > 0) {
                                    props.removeOption!(props.id)
                                } else {
                                    if (egFocusTextInput && text.length === 0) {
                                        egFocusTextInput.focus()
                                    }
                                }
                            }
                        }}
                    >
                        {getIcon()}
                    </AnswerOptionIcon>
                )}

                {props.option && <AnswerOptionText>{props.option.value}</AnswerOptionText>}
                {props.userDefined && (
                    <>
                        <TextField
                            id={props.id}
                            width={props.onlyChoice ? "100%" : "95%"}
                            height="30px"
                            fontSize="14px"
                            fontFamily={branding.font1}
                            textColor={branding.loginRegistrationSite.mobileLoginTextColor}
                            value={text}
                            maxLength={MAX_TITLE_LENGTH}
                            borderColor={branding.myProfilePageContent.textFieldsBorderColor}
                            setValue={(value: string) => {
                                if (props.blockSelection) return
                                setText(value)
                            }}
                            placeholder={
                                props.onlyChoice
                                    ? strings.myProfilePageContent.shortAnswerTextPlaceholder
                                    : strings.myProfilePageContent.userDefinedOptionPlaceholder
                            }
                            isSearchBar={false}
                            isEditProfile
                        />
                        <div style={{ width: "25px" }}>
                            {text.length > 0 && !props.onlyChoice && (
                                <div
                                    onClick={() => {
                                        if (props.userDefined) {
                                            if (props.numberOfOptions && props.numberOfOptions === 1) {
                                                setText("")
                                            } else {
                                                props.removeOption!(props.id)
                                            }
                                        }
                                    }}
                                >
                                    {IconCloseNotification({
                                        fill: branding.sideIconBar.sideIconColorDark,
                                        width: "10",
                                        height: "10"
                                    })}
                                </div>
                            )}
                        </div>
                    </>
                )}
            </AnswerOptionContainer>

            {props.userDefined && (
                <TextLimitContainer>
                    <TextLimit textLength={text.length} maxTextLength={MAX_TITLE_LENGTH} />
                </TextLimitContainer>
            )}
        </div>
    )
})

export const Attributes: React.FunctionComponent<SectionProps> = React.memo((props: SectionProps) => {
    const [answers, setAnswers] = useState<any[]>(props.user?.answers || [])
    useEffect(() => {
        props.setUser({ ...props.user!, answers: answers })
        // eslint-disable-next-line
    }, [answers])

    return (
        <EditProfileInformationField>
            <Row style={{ marginRight: "5px" }}>
                {props.questions?.map((question, index) => {
                    return (
                        <UserQuestionComponent
                            order={index + 1}
                            question={question}
                            answers={answers[question.id as any] || []}
                            updateAnswers={(values: any) => {
                                setAnswers({ ...answers, [question.id]: values })
                            }}
                        />
                    )
                })}
            </Row>
        </EditProfileInformationField>
    )
})

export function getSocialMediaFields(user: User, setUser: (value: User) => void, strings: any, mobile?: boolean) {
    return (
        <>
            {branding.myProfilePageContent.availableSocialMedias.map((item, index) => {
                if (item === SocialMediaType.FACEBOOK) {
                    return (
                        <SocialMediaItem
                            key={item}
                            labelText={strings.myProfilePageContent.facebookLinkLabel}
                            type={SocialMediaType.FACEBOOK}
                            value={user?.facebook || ""}
                            fieldMarker={Field.FACEBOOK}
                            updateValue={(value: string) => setUser({ ...user!, facebook: value })}
                            icon={
                                branding.socialMediaIcons.useOriginalSocialMediaIcons
                                    ? IconFacebookOriginal({ width: "20", height: "20" })
                                    : IconFacebook({
                                          fill: branding.sideIconBar.sideIconColorDark,
                                          width: "20",
                                          height: "20"
                                      })
                            }
                            baseLink={SocialMediaBaseLink.FACEBOOK}
                            mobile={mobile}
                        />
                    )
                }

                if (item === SocialMediaType.TWITTER) {
                    return (
                        <SocialMediaItem
                            key={item}
                            labelText={strings.myProfilePageContent.twitterLinkLabel}
                            type={SocialMediaType.TWITTER}
                            value={user?.twitter || ""}
                            updateValue={(value: string) => setUser({ ...user!, twitter: value })}
                            fieldMarker={Field.TWITTER}
                            icon={
                                branding.socialMediaIcons.useOriginalSocialMediaIcons
                                    ? IconTwitterOriginal({ width: "20", height: "20" })
                                    : IconTwitter({
                                          fill: branding.sideIconBar.sideIconColorDark,
                                          width: "20",
                                          height: "20"
                                      })
                            }
                            baseLink={SocialMediaBaseLink.TWITTER}
                            mobile={mobile}
                        />
                    )
                }

                if (item === SocialMediaType.LINKEDIN) {
                    return (
                        <SocialMediaItem
                            key={item}
                            labelText={strings.myProfilePageContent.linkedInLinkLabel}
                            type={SocialMediaType.LINKEDIN}
                            value={user?.linkedIn || ""}
                            updateValue={(value: string) => setUser({ ...user!, linkedIn: value })}
                            fieldMarker={Field.LINKEDIN}
                            icon={
                                branding.socialMediaIcons.useOriginalSocialMediaIcons
                                    ? IconLinkedInOriginal({ width: "20", height: "20" })
                                    : IconLinkedIn({
                                          fill: branding.sideIconBar.sideIconColorDark,
                                          width: "20",
                                          height: "20"
                                      })
                            }
                            baseLink={SocialMediaBaseLink.LINKEDIN}
                            mobile={mobile}
                        />
                    )
                }

                if (item === SocialMediaType.XING) {
                    return (
                        <SocialMediaItem
                            key={item}
                            labelText={strings.myProfilePageContent.xingLinkLabel}
                            type={SocialMediaType.XING}
                            value={user?.xing || ""}
                            fieldMarker={Field.XING}
                            updateValue={(value: string) => setUser({ ...user!, xing: value })}
                            icon={
                                branding.socialMediaIcons.useOriginalSocialMediaIcons
                                    ? IconXingOriginal({ width: "20", height: "20" })
                                    : IconXing({
                                          fill: branding.sideIconBar.sideIconColorDark,
                                          width: "20",
                                          height: "20"
                                      })
                            }
                            baseLink={SocialMediaBaseLink.XING}
                            mobile={mobile}
                        />
                    )
                }

                if (item === SocialMediaType.INSTAGRAM) {
                    return (
                        <SocialMediaItem
                            key={item}
                            labelText={strings.myProfilePageContent.instagramLinkLabel}
                            type={SocialMediaType.INSTAGRAM}
                            value={user?.instagram || ""}
                            updateValue={(value: string) => setUser({ ...user!, instagram: value })}
                            fieldMarker={Field.INSTAGRAM}
                            icon={
                                branding.socialMediaIcons.useOriginalSocialMediaIcons
                                    ? IconInstagramOriginal({ width: "20", height: "20" })
                                    : IconInstagram({
                                          fill: branding.sideIconBar.sideIconColorDark,
                                          width: "20",
                                          height: "20"
                                      })
                            }
                            baseLink={SocialMediaBaseLink.INSTAGRAM}
                            mobile={mobile}
                        />
                    )
                }

                if (item === SocialMediaType.YOUTUBE) {
                    return (
                        <SocialMediaItem
                            key={item}
                            labelText={strings.myProfilePageContent.youTubeLinkLabel}
                            type={SocialMediaType.YOUTUBE}
                            value={user?.youTube || ""}
                            updateValue={(value: string) => setUser({ ...user!, youTube: value })}
                            fieldMarker={Field.YOUTUBE}
                            icon={
                                branding.socialMediaIcons.useOriginalSocialMediaIcons
                                    ? IconYouTubeOriginal({ width: "20", height: "20" })
                                    : IconYouTube({
                                          fill: branding.sideIconBar.sideIconColorDark,
                                          width: "20",
                                          height: "20"
                                      })
                            }
                            baseLink={SocialMediaBaseLink.YOUTUBE}
                            mobile={mobile}
                        />
                    )
                }

                if (item === SocialMediaType.PINTEREST) {
                    return (
                        <SocialMediaItem
                            key={item}
                            labelText={strings.myProfilePageContent.pinterestLinkLabel}
                            type={SocialMediaType.PINTEREST}
                            value={user?.pinterest || ""}
                            updateValue={(value: string) => setUser({ ...user!, pinterest: value })}
                            fieldMarker={Field.PINTEREST}
                            icon={
                                branding.socialMediaIcons.useOriginalSocialMediaIcons
                                    ? IconPinterestOrginal({ width: "20", height: "20" })
                                    : IconPinterest({
                                          fill: branding.sideIconBar.sideIconColorDark,
                                          width: "20",
                                          height: "20"
                                      })
                            }
                            baseLink={SocialMediaBaseLink.PINTEREST}
                            mobile={mobile}
                        />
                    )
                }
                return null
            })}
        </>
    )
}

export const Socials: React.FunctionComponent<SectionProps> = React.memo((props: SectionProps) => {
    const strings = useLanguageState().getStrings()

    return (
        <EditProfileInformationField>
            <Row style={{ marginRight: "5px" }}>{getSocialMediaFields(props.user!, props.setUser, strings)}</Row>
        </EditProfileInformationField>
    )
})

export const RequiredInterestsWarning = styled.div`
    font-family: ${branding.font1};
    font-size: 14px;
    line-height: 16px;
    color: ${branding.mainInfoColor};
    margin-top: 20px;
`

interface CategorySectionProps {
    categoryData: CategoryData
    setCategoryData: (data: CategoryData) => void
}

export const Interests: React.FunctionComponent<CategorySectionProps> = React.memo((props: CategorySectionProps) => {
    const strings = useLanguageState().getStrings()
    const requiredInterestsNumber = branding.myProfilePageContent.requiredInterestsNumber
    const required = requiredInterestsNumber > 0

    return (
        <EditProfileInformationField>
            <Row style={{ marginLeft: "0px", marginRight: "28px" }}>
                <EditProfileInformationField style={{ marginTop: "-15px", width: "100%" }}>
                    <EditProfileLabel style={{ marginTop: "10px", marginBottom: "-10px" }}>
                        {strings.myProfilePageContent.yourInterestsSectionTitle}{" "}
                        {<RequiredFieldLabel visible={required}>*</RequiredFieldLabel>}
                    </EditProfileLabel>

                    {requiredInterestsNumber > 0 && (
                        <RequiredInterestsWarning>
                            {requiredInterestsNumber === 1
                                ? strings.myProfilePageContent.requiredInterestsWarningSingle
                                : strings.myProfilePageContent.requiredInterestsWarningMultiple.replace(
                                      "${number}",
                                      requiredInterestsNumber.toString()
                                  )}
                        </RequiredInterestsWarning>
                    )}
                    <ProfileCategories
                        type={CategoryType.INTERESTS}
                        setCategoryBindings={(newInterests) =>
                            props.setCategoryData({ ...props.categoryData, selectedInterests: newInterests })
                        }
                        data={props.categoryData}
                        required={required}
                    />
                </EditProfileInformationField>
            </Row>
        </EditProfileInformationField>
    )
})

export const BuySell: React.FunctionComponent<CategorySectionProps> = React.memo((props: CategorySectionProps) => {
    const strings = useLanguageState().getStrings()
    const [offersSectionTitle, setOffersSectionTitle] = useState<string>(strings.myProfilePageContent.imOfferingLabel)
    const [needsSectionTitle, setNeedsSectionTitle] = useState<string>(strings.myProfilePageContent.imLookingToBuyLabel)

    return (
        <EditProfileInformationField>
            <Row style={{ marginLeft: "0px", marginRight: "28px" }}>
                <EditProfileInformationField style={{ marginTop: "-15px", width: "100%" }}>
                    <EditProfileLabel style={{ marginTop: "10px", marginBottom: "-10px" }}>{offersSectionTitle}</EditProfileLabel>
                    <ProfileCategories
                        type={CategoryType.OFFERS}
                        setCategoryBindings={(newOffers) =>
                            props.setCategoryData({ ...props.categoryData, selectedOfferings: newOffers })
                        }
                        data={props.categoryData}
                        limitNumberOfSelectedCategories={true}
                        setSectionTitle={setOffersSectionTitle}
                    />
                </EditProfileInformationField>
            </Row>
            <Row style={{ marginLeft: "0px", marginRight: "28px" }}>
                <DummyDiv style={{ width: "100%", marginTop: "25px", marginBottom: "10px" }} />
            </Row>

            <Row style={{ marginLeft: "0px", marginRight: "28px" }}>
                <EditProfileInformationField style={{ width: "100%" }}>
                    <EditProfileLabel style={{ marginTop: "10px", marginBottom: "-10px" }}>{needsSectionTitle}</EditProfileLabel>
                    <ProfileCategories
                        type={CategoryType.NEEDS}
                        setCategoryBindings={(newLookingfor) =>
                            props.setCategoryData({ ...props.categoryData, selectedLookingfor: newLookingfor })
                        }
                        data={props.categoryData}
                        limitNumberOfSelectedCategories={true}
                        setSectionTitle={setNeedsSectionTitle}
                    />
                </EditProfileInformationField>
            </Row>
        </EditProfileInformationField>
    )
})

export function isImageSizeValid(imageSize: number, imageDimensions: { height: 0; width: 0 }): boolean {
    const minHeight = branding.myProfilePageContent.minPictureHeight ?? 500
    const minWidth = branding.myProfilePageContent.minPictureWidth ?? 500

    if (imageSize > 1000000 || imageDimensions.height < minHeight || imageDimensions.width < minWidth) {
        return false
    }
    return true
}

export function isCompanyNameCorrect(companyName: string, field: Field): boolean {
    if (!isInFieldsList(field)) {
        return true
    }

    return true
    /* TODO
    const companyRegex: RegExp = /^((?![\^!@#$*~ <>?]).)((?![\^!@#$*~<>?]).){0,73}((?![\^!@#$*~ <>?]).)$/g
    return companyRegex.test(companyName.trim())
    */
}

export function isNameCorrect(name: string, field: Field): boolean {
    if (!isInFieldsList(field)) {
        return true
    }
    return true
    /* TODO
    const nameRegex: RegExp = /\d/
    return !(nameRegex.test(name.trim()))
    */
    //checks if name string contains numbers, hence why the opposite value is returned - if string contains numbers, regex return "true", but the function returns "false", meaning that the name is not in the correct format
}

export function isPostalCodeCorrect(postalCode: string, field: Field): boolean {
    if (!isInFieldsList(field)) {
        return true
    }

    /* TODO
    if (postalCode.length > 0) {
        const postalCodeRegex: RegExp = /^\d+$/;
        return (postalCodeRegex.test(postalCode.trim()) && postalCode.trim().length >= 5 && postalCode.trim().length <= 7)
    }
    */
    return true
}

// function isAdressCorrect(adress: string): boolean {
//     /* TODO
//     if (adress.length > 0) {
//         return true
//     }
//     */
//     return true
// }

// function isCityCorrect(city: string): boolean {
//     /* TODO
//     if (city.length > 0) {
//         return true
//     }
//     return false
//     */
//     return true
// }

export function isValidationCorrect(user: User) {
    if (
        isNameCorrect(user?.firstName!, Field.FIRSTNAME) &&
        isNameCorrect(user?.lastName!, Field.LASTNAME) &&
        isCompanyNameCorrect(user?.company!, Field.COMPANY) &&
        isNameCorrect(user?.position!, Field.POSITION) &&
        isNameCorrect(user?.positionDe!, Field.POSITION) &&
        isNameCorrect(user?.industry!, Field.INDUSTRY) &&
        areSocialMediaLinksCorrect(user) &&
        isPostalCodeCorrect(user?.postalCode!, Field.POSTAL_CODE)
    ) {
        return true
    }

    return false
}

export function trimValues(user: User, setUser: (value: User) => void) {
    setUser({
        ...user,
        firstName: user?.firstName?.trim()!,
        lastName: user?.lastName?.trim()!,
        company: user?.company?.trim()!,
        position: user?.position?.trim()!,
        positionDe: user?.positionDe?.trim()!,
        infotext: user?.infotext?.trim()!,
        infotextDe: user?.infotextDe?.trim()!,
        industry: user?.industry?.trim()!,
        industryDe: user?.industryDe?.trim()!,
        mobile: user?.mobile?.trim()!,
        phone: user?.phone?.trim()!,
        adress1: user?.adress1?.trim()!,
        adress2: user?.adress2?.trim()!,
        adress3: user?.adress3?.trim()!,
        city: user?.city?.trim()!,
        postalCode: user?.postalCode?.trim()!,
        facebook: user?.facebook?.trim()!,
        twitter: user?.twitter?.trim()!,
        linkedIn: user?.linkedIn?.trim()!,
        xing: user?.xing?.trim()!,
        instagram: user?.instagram?.trim()!,
        youTube: user?.youTube?.trim()!,
        pinterest: user?.pinterest?.trim()!
    })
}

export function getUserPayload(userState: any, user: User, categoryData: CategoryData): any {
    const payload: any = {
        ...userState.user(),
        id: userState.user()?.profileId,
        salutation: user?.salutation || user?.salutationDe || "",
        salutationDe: user?.salutationDe || user?.salutation || "",
        firstName: user?.firstName || "",
        lastName: user?.lastName || "",
        logoUrl: user?.logoUrl || "",
        company: user?.company || "",
        position: user?.position || user?.positionDe || "",
        positionDe: user?.positionDe || user?.position || "",
        languages: user?.languages || [],
        industry: user?.industry || user?.industryDe || "",
        industryDe: user?.industryDe || "",
        countrycode: user?.countrycode || "",
        emailLanguage: user?.emailLanguage || "",
        infotext: user?.infotext || "",
        infotextDe: user?.infotextDe || "",
        mobile: user?.mobile || "",
        phone: user?.phone || "",
        adress1: user?.adress1 || "",
        adress2: user?.adress2 || "",
        adress3: user?.adress3 || "",
        city: user?.city || "",
        postalCode: user?.postalCode || "",
        facebook: user?.facebook || "",
        twitter: user?.twitter || "",
        linkedIn: user?.linkedIn || "",
        xing: user?.xing || "",
        instagram: user?.instagram || "",
        youTube: user?.youTube || "",
        pinterest: user?.pinterest || "",
        selfDefinedUserRoleId: user?.selfDefinedUserRoleId,
        answers: user?.answers || [],
        interests: categoryData.selectedInterests.map((i) => i.id),
        lookingfor: categoryData.selectedLookingfor.map((i) => i.id),
        offering: categoryData.selectedOfferings.map((i) => i.id)
    }

    return payload
}

const EditMyProfileLayout: React.FunctionComponent<EditProfileProps> = (props: EditProfileProps) => {
    let userState = useLoggedInState()
    const strings = useLanguageState().getStrings()
    const lang = useLanguageState().getLanguage()
    const appState = useAppState()

    const [user, setUser] = useState<User>(userState.user()!)

    const [basicInfoLang, setBasicInfoLang] = useState<string>(lang)

    const [userRoles, setUserRoles] = useState<SelfDefinedUserRole[]>([])

    const [activeTab, setActiveTab] = useState<EditTab>(EditTab.BASIC_INFO)

    const [userQuestions, setUserQuestions] = useState<UserQuestion[]>([])

    const [categoryData, setCategoryData] = useState<CategoryData>({
        interests: [],
        offerings: [],
        lookingfor: [],
        selectedInterests: [],
        selectedOfferings: [],
        selectedLookingfor: []
    })

    const [isLoaded, setIsLoaded] = useState<boolean>(true)
    const [isProfileImageChanged, setIsProfileImageChanged] = useState<boolean>(false)
    const [isProfileImageDeleted, setIsProfileImageDeleted] = useState<boolean>(false)

    const [imageDimensions, setImageDimensions] = useState<any>({ height: 0, width: 0 })
    const [imageSize, setImageSize] = useState<number>(0)
    const [imageFile, setImageFile] = useState<any>(null)

    const [showAlert, setShowAlert] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>(false)
    const [alertMessage, setAlertMessage] = useState<string>("")
    const history = useHistory()

    const windowSize = useWindowDimensions()

    let entityFilterItems: any = []

    const itemsOrder = branding.myProfilePageContent.editProfileTabs

    const onScroll = (scrollValues: any) => {
        if (props.showShadow) scrollValues.scrollTop > 0 ? props.showShadow(true) : props.showShadow(false)
    }

    itemsOrder.forEach((item) => {
        let newItem = {}

        switch (item) {
            case EditTab.BASIC_INFO:
                newItem = { label: strings.myProfilePageContent.basicInfoTabTitle, value: EditTab.BASIC_INFO }
                break

            case EditTab.SOCIAL_MEDIA:
                newItem = { label: strings.myProfilePageContent.socialMediaSectionTitle, value: EditTab.SOCIAL_MEDIA }
                break

            case EditTab.ATTRIBUTES:
                newItem = { label: strings.myProfilePageContent.attributesTabTitle, value: EditTab.ATTRIBUTES }
                break

            case EditTab.INTERESTS:
                newItem = { label: strings.myProfilePageContent.interestsLabel, value: EditTab.INTERESTS }
                break

            case EditTab.BUY_SELL:
                newItem = { label: strings.myProfilePageContent.buySellTabTitle, value: EditTab.BUY_SELL }
                break
        }

        entityFilterItems.push(newItem)
    })

    function getData() {
        getInterest().then((res) => {
            if (res.content.interests) {
                const interests: Category[] = res.content.interests ?? []
                const offerings: Category[] = res.content.lookingforandoffering ?? []
                const lookingfor: Category[] = res.content.lookingforandoffering ?? []
                const selectedInterests = interests.filter((i) => (userState.user()?.interests ?? []).includes(i.id))
                const selecteOfferings = offerings.filter((i) => (userState.user()?.offering ?? []).includes(i.id))
                const selectedLookingfor = lookingfor.filter((i) => (userState.user()?.lookingfor ?? []).includes(i.id))
                setCategoryData({
                    interests: interests,
                    offerings: offerings,
                    lookingfor: lookingfor,
                    selectedInterests: selectedInterests,
                    selectedOfferings: selecteOfferings,
                    selectedLookingfor: selectedLookingfor
                })
            }
        })

        getSelfDefinedUserRolesByTopic().then((res) => {
            if (res.content.selfDefinedUserRoles) {
                setUserRoles(res.content.selfDefinedUserRoles)
            }
        })

        getUserQuestions().then((res) => {
            if (res.content.questions) {
                setUserQuestions(res.content.questions)
            }
        })
    }

    useEffect(() => {
        getData()
    }, [lang]) // eslint-disable-line react-hooks/exhaustive-deps

    function resetValues() {
        setUser(userState.user()!)
    }

    function updateValues() {
        setAlertMessage(strings.myProfilePageContent.dataSuccessfullyUpdatedText)

        updateMyProfileData({
            profileId: userState.user()!.profileId,
            profileData: getUserPayload(userState, user, categoryData)
        }).then(async (res) => {
            if ((res.content as BackendServiceError).httpStatus) {
                logger.error("failure updating profile data for user " + userState.user()?.profileId)

                setIsLoaded(true)
                setSuccess(false)

                setAlertMessage(strings.myProfilePageContent.backendErrorWarning)

                setShowAlert(true)
            } else {
                res.content.profileId = res.content.id
                userState.setMatchActive(res.content.matchActive)
                userState.setUser(res.content)
            }

            const userObject = userState.user()!
            const userName = [userObject.firstName, userObject.middleName, userObject.lastName].filter(Boolean).join(" ")
            await updateUserValues({
                id: userObject.profileId,
                name: userName,
                pictureUrl: userObject.logoUrl,
                presenceStatus: userObject.presence,
                lastConnected: new Date().toISOString()
            })

            setIsLoaded(true)

            setSuccess(true)
            setShowAlert(true)

            const timeout = setTimeout(() => {
                props.setMyProfileMode(MyProfilePageMode.VIEW)
                history.push(myProfilePageRoute)
            }, 2000)

            return () => clearTimeout(timeout)
        })
    }

    function handleDataChange() {
        setIsLoaded(false)

        if (
            areRequiredFieldsEmpty(user, branding.myProfilePageContent.editProfileFieldsList, basicInfoLang) ||
            areRequiredInterestsEmpty(categoryData) ||
            (branding.myProfilePageContent.editProfileTabs.includes(EditTab.ATTRIBUTES) &&
                getAttributesValidationErrors(user, userQuestions))
        ) {
            setIsLoaded(true)

            setSuccess(false)
            setAlertMessage(strings.myProfilePageContent.requiredFieldsEmptyWarning)

            setShowAlert(true)
            return
        }

        if (isProfileImageChanged) {
            if (!isImageSizeValid(imageSize, imageDimensions)) {
                setIsLoaded(true)

                setSuccess(false)
                setAlertMessage(strings.myProfilePageContent.pictureSizeWarning)

                setShowAlert(true)
                return
            }
        }

        if (!isPhoneNumberCorrect(user?.phone!, Field.PHONE) || !isPhoneNumberCorrect(user?.mobile!, Field.MOBILE)) {
            setIsLoaded(true)

            setSuccess(false)
            setAlertMessage(strings.myProfilePageContent.phoneValidationIncorrectWarning)

            setShowAlert(true)
            return
        }

        if (!isValidationCorrect(user!)) {
            setIsLoaded(true)

            setSuccess(false)
            setAlertMessage(strings.myProfilePageContent.validationIncorrectWarning)

            setShowAlert(true)
            return
        }

        if (isProfileImageDeleted) {
            userState.setProfileImage("")
            setUser({ ...user, logoUrl: "" })

            deleteProfileImage(userState.user()?.profileId || "")
                .then((res) => {
                    userState.setProfileImage("")
                    setUser({ ...user, logoUrl: "" })
                    updateValues()
                })
                .catch((error) => {
                    logger.error({ message: "EditMyProfileLayout Error", errorMessage: error.message, errorStack: error.stack })
                })

            return
        } else if (isProfileImageChanged) {
            if (imageFile && imageFile !== null) {
                let imageData = new FormData()
                imageData.append("image", imageFile)

                changeProfileImage({ profileId: userState.user()?.profileId || "", data: imageData })
                    .then((res) => {
                        if (res.logoUrl) {
                            userState.setProfileImage(res.logoUrl || "")
                            setUser({ ...user, logoUrl: res.logoUrl })

                            updateValues()
                        }
                    })
                    .catch((error) => {
                        logger.error({
                            message: "EditMyProfileLayout Profile Logo Upload failed",
                            errorMessage: error.message,
                            errorStack: error.stack
                        })
                    })
            }

            return
        } else {
            updateValues()
        }
    }

    function TabContent() {
        switch (activeTab) {
            case EditTab.BASIC_INFO: {
                return (
                    <TabContentContainer>
                        <BasicInfo
                            user={user}
                            setUser={setUser}
                            basicInfoLang={basicInfoLang}
                            setBasicInfoLang={setBasicInfoLang}
                            fieldsList={branding.myProfilePageContent.editProfileFieldsList}
                            userRoles={userRoles}
                            showLanguageSelector
                        />
                    </TabContentContainer>
                )
            }

            case EditTab.SOCIAL_MEDIA: {
                return (
                    <TabContentContainer>
                        <Socials
                            user={user}
                            setUser={setUser}
                            basicInfoLang={basicInfoLang}
                            setBasicInfoLang={setBasicInfoLang}
                        />
                    </TabContentContainer>
                )
            }

            case EditTab.ATTRIBUTES: {
                return (
                    <Attributes
                        user={user}
                        setUser={setUser}
                        basicInfoLang={basicInfoLang}
                        setBasicInfoLang={setBasicInfoLang}
                        questions={userQuestions}
                    />
                )
            }

            case EditTab.INTERESTS: {
                return (
                    <TabContentContainer>
                        <Interests categoryData={categoryData} setCategoryData={setCategoryData} />
                    </TabContentContainer>
                )
            }

            case EditTab.BUY_SELL: {
                return (
                    <TabContentContainer>
                        <BuySell categoryData={categoryData} setCategoryData={setCategoryData} />
                    </TabContentContainer>
                )
            }

            default:
                return <div />
        }
    }

    function TabNavigation() {
        const largeScreen: boolean = windowSize.width >= 1502
        let activeTabIndex: number = itemsOrder.indexOf(activeTab)

        if (itemsOrder.length <= 1) {
            return null
        }

        return (
            <>
                <Row style={{ marginRight: "5px" }}>
                    <Col md={6} className={"d-flex align-items-center"} style={{ paddingLeft: "40px" }}>
                        {!largeScreen && <DeleteProfile />}
                    </Col>

                    <Col md={6}>
                        <EditProfileSectionFooter useOneButton={activeTabIndex === 0 || activeTabIndex === itemsOrder.length - 1}>
                            {activeTabIndex === 0 ? (
                                <UploadProfileImageButton onClick={() => setActiveTab(itemsOrder[activeTabIndex + 1])}>
                                    {strings.myProfilePageContent.continueButtonTitle}
                                </UploadProfileImageButton>
                            ) : activeTabIndex === itemsOrder.length - 1 ? (
                                <DeleteProfileImageButton onClick={() => setActiveTab(itemsOrder[0])}>
                                    {strings.myProfilePageContent.backButtonTitle}
                                </DeleteProfileImageButton>
                            ) : (
                                <>
                                    <DeleteProfileImageButton onClick={() => setActiveTab(itemsOrder[activeTabIndex - 1])}>
                                        {strings.myProfilePageContent.backButtonTitle}
                                    </DeleteProfileImageButton>

                                    <UploadProfileImageButton onClick={() => setActiveTab(itemsOrder[activeTabIndex + 1])}>
                                        {strings.myProfilePageContent.continueButtonTitle}
                                    </UploadProfileImageButton>
                                </>
                            )}
                        </EditProfileSectionFooter>
                    </Col>
                </Row>
            </>
        )
    }

    return (
        <div style={{ fontFamily: branding.font1, color: branding.mainInfoColor }}>
            <div style={{ backgroundColor: "white" }}>
                <ScrollContainerRoot guestBannerHeight={props.guestBannerHeight}>
                    <SaveCancelContainer ccOpen={appState.isNetworkingOpen()} guestBannerHeight={props.guestBannerHeight}>
                        <div style={{ visibility: isLoaded ? "hidden" : "visible" }}>
                            <SaveLoader />
                        </div>
                        <DeleteProfileImageButton
                            onClick={() => {
                                resetValues()
                                props.setMyProfileMode(MyProfilePageMode.VIEW)
                                history.push(myProfilePageRoute)
                            }}
                        >
                            {strings.myProfilePageContent.cancelButtonText}
                        </DeleteProfileImageButton>

                        <UploadProfileImageButton
                            onClick={() => {
                                trimValues(user, setUser)
                                handleDataChange()
                            }}
                        >
                            {strings.myProfilePageContent.saveButtonText}
                        </UploadProfileImageButton>
                    </SaveCancelContainer>
                    <ContentScrollContainer handleScroll={onScroll}>
                        <div style={{ position: "relative" }}>
                            <ProfileDetailRowHeader>
                                <ProfileImage
                                    user={user!}
                                    setUser={setUser}
                                    setIsProfileImageDeleted={setIsProfileImageDeleted}
                                    setIsProfileImageChanged={setIsProfileImageChanged}
                                    setImageDimensions={setImageDimensions}
                                    setImageSize={setImageSize}
                                    setImageFile={setImageFile}
                                />

                                <ProfileDetailNameTeaserCol
                                    xs={20}
                                    style={{ marginLeft: windowSize.width >= 1502 ? "60px" : "0px" }}
                                >
                                    <CrsTabs
                                        tabItems={entityFilterItems}
                                        activeItem={activeTab}
                                        onTabItemClicked={(value: any) => setActiveTab(value)}
                                        itemWidth={150}
                                        width={"100%"}
                                    />

                                    {TabContent()}
                                    {TabNavigation()}
                                </ProfileDetailNameTeaserCol>
                            </ProfileDetailRowHeader>
                        </div>
                    </ContentScrollContainer>
                </ScrollContainerRoot>

                {showAlert && (
                    <DataUpdateAlert
                        message={alertMessage}
                        close={() => setShowAlert(false)}
                        show={showAlert}
                        type="edit-profile"
                        titleImage={
                            success ? IconCheckmarkSuccessfull({}) : IconIndicatorErrorBlack({ fill: branding.dangerButtonColor })
                        }
                        position="top"
                        fontSize="15px"
                        color={success ? branding.sideIconBar.sideIconColorDark : branding.dangerButtonColor}
                        marginTop={success ? "0px" : "10px"}
                    />
                )}
            </div>
        </div>
    )
}

export default EditMyProfileLayout

const EditProfileInformationField = styled.div`
    margin-top: 25px;
    text-align: left;
    margin-bottom: 10px;
`

const EditProfileLabel = styled.div`
    display: flex;
    flex-direction: row;
    text-align: left;
    font-size: 16px;
    line-height: 19px;
    font-family: ${branding.font1};
    margin-bottom: 8px;
    font-weight: bold;

    @media ${device.mobile} {
        font-size: 14px;
    }
`

const EditProfileSectionFooter = styled.div<{ useOneButton: boolean }>`
    width: 100%;
    margin-top: 30px;
    display: flex;
    align-items: flex-end;
    gap: 10px;
    margin-left: ${(props) => (props.useOneButton ? "190px" : "55px")};
`

const UploadProfileImageButton = styled.button`
    flex: 0 0 auto;
    //margin-top: 10px;
    margin-bottom: 20px;
    padding: 7px;
    min-height: 35px;
    height: auto;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid ${branding.crsTabs.defaultActionItemActiveStateColor};
    color: #fff;
    background: ${branding.crsTabs.defaultActionItemActiveStateColor};
    transition: 0.5s;
    cursor: pointer;
    width: 125px;
    outline: none;

    &.userRegistrationPage  {
        font-size: 14px;
        padding: 5px;
        width: auto;
        min-width: 125px;
    }

    &.noImage {
        align-self: center;
        margin-top: 15px;
        margin-bottom: 10px;
    }

    &:focus {
        outline: none;
    }

    ${(props) =>
        props.disabled
            ? css`
                  opacity: 0.5;
                  transition-property: none;
              `
            : css`
                  &:hover {
                      opacity: 0.7;
                  }
              `};
`

const DeleteProfileImageButton = styled.button`
    flex: 0 0 auto;
    //margin-top: 10px;
    margin-bottom: 20px;
    padding: 7px;
    min-height: 35px;
    height: auto;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid ${branding.crsTabs.defaultActionItemActiveStateColor};
    color: ${branding.crsTabs.defaultActionItemActiveStateColor};
    background: #fff;
    transition: 0.5s;
    cursor: pointer;
    width: 125px;
    outline: none;

    &:focus {
        outline: none;
    }

    &.userRegistrationPage  {
        font-size: 14px;
        padding: 5px;
    }

    ${(props) =>
        props.disabled
            ? css`
                  opacity: 0.5;
                  transition-property: none;
              `
            : css`
                  &:hover {
                      opacity: 0.7;
                  }
              `};
`

const LabelText = styled.p`
    margin-top: 10px;
    font-size: 10px;
    font-weight: bold;
    line-height: 12px;
    font-family: ${branding.font1};
    max-width: 270px;
    text-align: center;

    &.parentCategory {
        text-align: left;
        margin-left: 5px;
        font-weight: normal;
    }
`

const ProfileImageUploadPlaceholder = styled.div<{ source?: string; columnSize: string }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 5px;
    background-image: url("${(props) => props.source}");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    text-align: center;
    position: relative;

    border: ${(props) => (props.source ? "none" : "1px dashed #c9c9c9")};
    width: ${(props) => props.columnSize ?? "335px"};
    height: ${(props) => props.columnSize ?? "335px"};
    max-width: 350px;
    max-height: 350px;

    &.plainContainer {
        border: none;
    }

    &.backgroundOverlay {
        background-color: rgba(0, 0, 0, 0.2);
        border: none;
    }

    &.innerCircle {
        border-radius: 50%;
    }

    &.invalid {
        outline: 1px solid ${branding.dangerButtonColor};
    }
`

export interface ProfileImageProps {
    user?: User
    setUser: (user: User) => void

    setIsProfileImageDeleted?: (value: boolean) => void
    setIsProfileImageChanged?: (value: boolean) => void

    setImageDimensions: (values: any) => void

    setImageSize: (value: number) => void
    setImageFile: (value: any) => void

    userRegistrationPage?: boolean

    mobile?: boolean
}

export const ProfileImage: React.FunctionComponent<ProfileImageProps> = React.memo((props: ProfileImageProps) => {
    const languageState = useLanguageState()
    const [localization, setLocalization] = useState(languageState.getStrings())
    const lang = languageState.getLanguage()
    const columnRef = useRef<HTMLDivElement>(null)
    const [showWarning, setShowWarning] = useState<boolean>(false)

    const [columnRefWidth, setColumnRefWidth] = useState<number>(350)

    const windowSize = useWindowDimensions()

    const largeScreen: boolean = windowSize.width >= 1502

    let imageInput: HTMLInputElement | null

    useEffect(() => {
        setLocalization(languageState.getStrings())
        // eslint-disable-next-line
    }, [lang])

    useEffect(() => {
        if (columnRef && columnRef?.current) {
            setColumnRefWidth(columnRef?.current?.clientWidth!)
        }
    }, [columnRef])

    function triggerProfileImageInputClick() {
        if (imageInput) {
            imageInput.click()
        }
    }

    function handleProfileImageChange() {
        if (imageInput && imageInput !== null && imageInput.files) {
            if (props.setIsProfileImageDeleted) {
                props.setIsProfileImageDeleted!(false)
            }

            if (props.setIsProfileImageChanged) {
                props.setIsProfileImageChanged!(true)
            }

            var binaryData = []
            binaryData.push(imageInput.files[0])

            const objectURL = URL.createObjectURL(new Blob(binaryData, { type: "image" }))
            let imageSize = imageInput.files[0].size
            props.setImageSize(imageSize)
            props.setUser({ ...props.user!, logoUrl: objectURL })
            props.setImageFile(imageInput.files[0])

            var reader = new FileReader()
            reader.onloadend = function () {
                var image = new Image()
                image.src = objectURL
                image.onload = function () {
                    let imageDimensions: any = { height: image.naturalHeight, width: image.naturalWidth }
                    props.setImageDimensions(imageDimensions)
                    setShowWarning(!isImageSizeValid(imageSize, imageDimensions))
                }
            }

            reader.readAsDataURL(imageInput.files[0])
        } else {
            setShowWarning(false)
        }
    }

    function handleProfileImageDelete() {
        if (props.setIsProfileImageDeleted) {
            props.setIsProfileImageDeleted!(true)
        }

        if (props.setIsProfileImageChanged) {
            props.setIsProfileImageChanged!(false)
        }
        props.setUser({ ...props.user!, logoUrl: "" })
    }

    if (props.mobile) {
        return (
            <>
                <input
                    className="d-none"
                    ref={(input) => (imageInput = input)}
                    type="file"
                    value=""
                    onChange={handleProfileImageChange}
                    accept=".jpg,.jpeg,.png"
                />
                <UserImageDiv className="mobile">
                    <AvatarWithDefault
                        size={80}
                        src={props.user?.logoUrl}
                        alt={[props.user?.firstName!, props.user?.lastName!].join(" ") ?? "#"}
                        backgroundSize="cover"
                        onClick={triggerProfileImageInputClick}
                    />
                </UserImageDiv>
                <EditPhotoButton onClick={triggerProfileImageInputClick}>
                    {localization.myProfilePageContent.editPhotoMobileButtonTitle}
                </EditPhotoButton>
                <EmailContainer style={{ marginTop: "10px" }}>{props.user?.email}</EmailContainer>
            </>
        )
    }

    return (
        <>
            {props.userRegistrationPage ? (
                <Row style={{ marginRight: "5px", marginLeft: "-13px", marginTop: "25px" }}>
                    <Col xs={12} md={6}>
                        <div
                            style={{ width: "100%", position: "relative", display: "flex", justifyContent: "center" }}
                            ref={columnRef}
                        >
                            <input
                                className="d-none"
                                ref={(input) => (imageInput = input)}
                                type="file"
                                value=""
                                onChange={handleProfileImageChange}
                                accept=".jpg,.jpeg,.png"
                            />
                            {props.user?.logoUrl && props.user?.logoUrl?.length! > 0 ? (
                                <>
                                    <ProfileImageUploadPlaceholder
                                        source={encodePictureUrl(props.user?.logoUrl!)}
                                        columnSize={columnRefWidth.toString() + "px"}
                                        className={showWarning ? "invalid" : ""}
                                    >
                                        <ProfileImageUploadPlaceholder
                                            source=""
                                            columnSize={columnRefWidth.toString() + "px"}
                                            className="backgroundOverlay"
                                        >
                                            <ProfileImageUploadPlaceholder
                                                source={encodePictureUrl(props.user?.logoUrl!)}
                                                columnSize={columnRefWidth.toString() + "px"}
                                                className="innerCircle"
                                            />
                                        </ProfileImageUploadPlaceholder>
                                    </ProfileImageUploadPlaceholder>
                                </>
                            ) : (
                                <ProfileImageUploadPlaceholder
                                    columnSize={(columnRef?.current?.clientWidth || 350).toString() + "px"}
                                >
                                    <div>{IconImageUploadPlaceholder({ fill: branding.sideIconBar.sideIconColorDark })}</div>
                                    <UploadProfileImageButton
                                        className="userRegistrationPage noImage"
                                        onClick={() => triggerProfileImageInputClick()}
                                        style={{}}
                                    >
                                        {localization.myProfilePageContent.uploadPhotoButtonText}
                                    </UploadProfileImageButton>
                                    <LabelText
                                        style={{
                                            fontSize: "11.5px",
                                            fontWeight: "normal",
                                            color: "#808080",
                                            alignSelf: "center",
                                            maxWidth: "90%"
                                        }}
                                    >
                                        {localization.myProfilePageContent.formatsText}
                                    </LabelText>
                                </ProfileImageUploadPlaceholder>
                            )}
                        </div>
                    </Col>
                    <Col xs={12} md={6}>
                        <div style={{ width: "100%", position: "relative", display: "flex", justifyContent: "center" }}>
                            {props.user?.logoUrl && props.user?.logoUrl?.length! > 0 ? (
                                <ProfileImageUploadPlaceholder
                                    className="plainContainer"
                                    columnSize={(columnRef?.current?.clientWidth || 350).toString() + "px"}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: "10px",
                                            justifyContent: "center"
                                        }}
                                    >
                                        <UploadProfileImageButton
                                            onClick={() => triggerProfileImageInputClick()}
                                            className="userRegistrationPage"
                                        >
                                            {localization.myProfilePageContent.uploadNewButtonText}
                                        </UploadProfileImageButton>
                                        <DeleteProfileImageButton
                                            onClick={() => handleProfileImageDelete()}
                                            className="userRegistrationPage"
                                        >
                                            {localization.myProfilePageContent.deleteCurrentPictureButtonText}
                                        </DeleteProfileImageButton>
                                    </div>
                                    <LabelText
                                        style={{
                                            fontSize: "11.5px",
                                            fontWeight: "normal",
                                            color: "#808080",
                                            alignSelf: "center",
                                            maxWidth: "90%"
                                        }}
                                    >
                                        {localization.myProfilePageContent.formatsText}
                                    </LabelText>
                                    {showWarning && (
                                        <LabelText
                                            style={{
                                                fontSize: "11.5px",
                                                fontWeight: "normal",
                                                color: branding.dangerButtonColor,
                                                alignSelf: "center",
                                                maxWidth: "90%"
                                            }}
                                        >
                                            {localization.myProfilePageContent.pictureSizeWarning}
                                        </LabelText>
                                    )}
                                </ProfileImageUploadPlaceholder>
                            ) : null}
                        </div>
                    </Col>
                </Row>
            ) : (
                <PersonImageAndLogo
                    className={largeScreen ? "col-3" : "col-md-12"}
                    style={largeScreen ? { height: "calc(100vh - 270px)" } : {}}
                >
                    <UserBasicInfoContainer>
                        <CustomRow>
                            <UserImageDiv>
                                <AvatarWithDefault
                                    size={100}
                                    src={props.user?.logoUrl!}
                                    alt={[props.user?.firstName!, props.user?.lastName!].join(" ") ?? "#"}
                                    backgroundSize="cover"
                                />
                            </UserImageDiv>
                        </CustomRow>

                        <CustomRow style={{ gap: "10px", marginTop: "20px" }}>
                            <div style={{ display: "inline-block" }}>
                                <input
                                    className="d-none"
                                    ref={(input) => (imageInput = input)}
                                    type="file"
                                    value=""
                                    onChange={handleProfileImageChange}
                                    accept=".jpg,.jpeg,.png"
                                />

                                <UploadProfileImageButton onClick={triggerProfileImageInputClick}>
                                    {localization.myProfilePageContent.uploadNewButtonText}
                                </UploadProfileImageButton>
                            </div>

                            <div style={{ display: "inline-block" }}>
                                <DeleteProfileImageButton onClick={() => handleProfileImageDelete()}>
                                    {localization.myProfilePageContent.deleteCurrentPictureButtonText}
                                </DeleteProfileImageButton>
                            </div>
                        </CustomRow>

                        <CustomRow>
                            <LabelText style={{ fontSize: "11.5px", fontWeight: "normal", color: "#808080" }}>
                                {localization.myProfilePageContent.formatsText}
                            </LabelText>
                        </CustomRow>

                        <CustomRow>
                            <ProfileSocialsTitle>
                                <Row>
                                    <ProfilePageSectionTitle style={{ marginLeft: "15px", marginBottom: "15px" }}>
                                        {localization.communicationArea.settingsTitle}
                                    </ProfilePageSectionTitle>
                                </Row>

                                <Row>
                                    <div style={{ width: largeScreen ? "270px" : "673px" }}>
                                        <NetworkingToggle />
                                    </div>
                                </Row>

                                <Row>
                                    <div style={{ width: largeScreen ? "270px" : "673px" }}>
                                        <DoNotDisturbToggle />
                                    </div>
                                </Row>
                            </ProfileSocialsTitle>
                        </CustomRow>

                        {branding.myProfilePageContent.showEmailLangPreference &&
                            branding.myProfilePageContent.basicInfoLanguagesList.length > 1 && (
                                <Row style={{ justifyContent: "center" }}>
                                    <div
                                        style={{
                                            marginLeft: largeScreen ? "0px" : "90px",
                                            borderTop: branding.mainBorder ?? "1px solid #d9d9d9",
                                            width: largeScreen ? "270px" : "673px"
                                        }}
                                    >
                                        <EmailLanguageSwitcher
                                            emailLanguage={props.user?.emailLanguage!}
                                            setEmailLanguage={(value: string) =>
                                                props.setUser({ ...props.user!, emailLanguage: value })
                                            }
                                        />
                                    </div>
                                </Row>
                            )}

                        {largeScreen && (
                            <CustomRow>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-end",
                                        padding: "50px 0px",
                                        width: "270px",
                                        textAlign: "center"
                                    }}
                                >
                                    <Row style={{ justifyContent: "center" }}>
                                        <Col>
                                            <DeleteProfile />
                                        </Col>
                                    </Row>
                                </div>
                            </CustomRow>
                        )}
                    </UserBasicInfoContainer>
                </PersonImageAndLogo>
            )}
        </>
    )
})

interface EmailLanguageSwitcherProps {
    emailLanguage: string
    setEmailLanguage: (value: string) => void
    mobile?: boolean
}

export const EmailLanguageSwitcher: React.FunctionComponent<EmailLanguageSwitcherProps> = React.memo(
    (props: EmailLanguageSwitcherProps) => {
        const languageState = useLanguageState()
        const [configuration, setConfiguration] = useState<any>(languageState.getStrings().myProfilePageContent)
        const lang = languageState.getLanguage()

        useEffect(() => {
            setConfiguration(languageState.getStrings().myProfilePageContent)
            // eslint-disable-next-line
        }, [lang])

        function getSwitcherValue() {
            return props.emailLanguage
                ? {
                      label: props.emailLanguage === "de" ? "Deutsch" : "English",
                      value: props.emailLanguage
                  }
                : null
        }

        return (
            <>
                {props.mobile ? (
                    <EditProfileLabel>{configuration.emailLangPreferenceLabel}</EditProfileLabel>
                ) : (
                    <Row style={{ marginTop: "20px", marginLeft: "2px", marginBottom: "15px" }}>
                        <ProfilePageSectionTitle>{configuration.emailLangPreferenceLabel}</ProfilePageSectionTitle>
                    </Row>
                )}

                <Row style={{ marginLeft: "2px" }}>
                    <DisplayProfileLangSelect
                        options={branding.myProfilePageContent.basicInfoLanguagesList}
                        placeholder={configuration.emailLangPreferenceText}
                        className={props.mobile ? "emailLangMobile" : "emailLang"}
                        value={getSwitcherValue()}
                        onChange={(value: ValueType<OptionTypeBase, boolean>[], action: ActionMeta<OptionTypeBase>) => {
                            if (value !== null) {
                                const option = value as OptionTypeBase

                                props.setEmailLanguage(option.value)
                            }
                        }}
                        theme={SelectThemeCustom}
                    />
                </Row>
            </>
        )
    }
)

function isInAvailableSocialMedias(socialMedia: SocialMediaType): boolean {
    if (branding.myProfilePageContent.availableSocialMedias.find((e: SocialMediaType) => e === socialMedia)) {
        return true
    }

    return false
}

function isSocialMediaLinkCorrect(link: string, type: SocialMediaType): boolean {
    if (!isInAvailableSocialMedias(type)) {
        return true
    }

    if (link.includes(" ")) {
        return false
    }

    return link.includes(type.toLowerCase())
}

export const RequiredFieldLabel = styled.span<{ visible: boolean }>`
    color: ${branding.dangerButtonColor};
    margin-top: -4px;
    margin-left: 2px;
    opacity: ${(props) => (props.visible ? "1" : "0")};
    transition: 0.5s;
`

export const RequiredFieldWarning = styled.div`
    font-size: 14px;
    color: ${branding.dangerButtonColor};
    text-align: right;
    margin-top: 10px;
    margin-bottom: -34px;
`

export function isRequiredAndEmpty(field: Field, value: string | any[] | undefined, requiredByDefault?: boolean): boolean {
    const required = requiredByDefault === true || branding.myProfilePageContent.editRequiredFields.includes(field)

    return required && isEmpty(value)
}

export function isEmpty(value: string | any[] | undefined): boolean {
    return value === "" || value === undefined || value?.length! === 0
}

interface ProfileInformationProps {
    required?: boolean
    labelText: string
    value: string
    updateValue: (value: string) => void
    fieldMarker: Field
    placeholder: string
    validation?: (value: string, field: Field) => boolean
    validationWarning?: string
    disabled?: boolean
    multiLine?: boolean
    requirementOverrideForPrivatePersons?: boolean
    showRequiredFieldWarning?: boolean //this property is here because required field warnings were not in the design for user registration page
}

export const ProfileInformation: React.FunctionComponent<ProfileInformationProps> = React.memo(
    (props: ProfileInformationProps) => {
        const strings = useLanguageState().getStrings()

        let required = props.required === true || branding.myProfilePageContent.editRequiredFields.includes(props.fieldMarker)

        if (props.requirementOverrideForPrivatePersons) {
            required = false
        }

        const disabled = props.disabled === true || branding.myProfilePageContent.editDisabledFields.includes(props.fieldMarker)

        const maxTextLength: number = 1000

        const [borderColor, setBorderColor] = useState(branding.myProfilePageContent.textFieldsBorderColor)

        useEffect(() => {
            if (
                (props.validation && !props.validation(props.value, props.fieldMarker)) ||
                (isRequiredAndEmpty(props.fieldMarker, props.value, props.required) &&
                    !props.requirementOverrideForPrivatePersons)
            ) {
                setBorderColor(branding.dangerButtonColor)
            } else setBorderColor(branding.myProfilePageContent.textFieldsBorderColor)
            // eslint-disable-next-line
        }, [props.validation, props.fieldMarker, props.value, props.requirementOverrideForPrivatePersons])

        return (
            <Col xs={props.multiLine ? 24 : 12} md={props.multiLine ? 12 : 6}>
                <EditProfileInformationField>
                    <EditProfileLabel>
                        <div>{props.labelText}</div> <RequiredFieldLabel visible={required && !disabled}>*</RequiredFieldLabel>
                    </EditProfileLabel>

                    {props.multiLine ? (
                        <>
                            <MultiLineTextField
                                width="100%"
                                height="130px"
                                fontSize="14px"
                                textColor={branding.mainInfoColor}
                                fontFamily={branding.font1}
                                value={props.value}
                                setValue={props.updateValue}
                                placeholder={props.placeholder}
                                rows={6}
                                backgroundColor={
                                    props.value === "" || props.value === undefined
                                        ? branding.myProfilePageContent.textFieldsBackgroundColor
                                        : ""
                                }
                                borderAround={"1px solid " + borderColor}
                                outline={"1px solid " + borderColor}
                                maxLength={maxTextLength}
                                zIndex={0}
                            />
                            <TextLimit textLength={props.value?.length || 0} maxTextLength={maxTextLength} />
                        </>
                    ) : (
                        <TextField
                            disabled={disabled}
                            width="100%"
                            height="40px"
                            fontSize="14px"
                            paddingLeft="8px"
                            textColor={branding.mainInfoColor}
                            fontFamily={branding.font1}
                            value={props.value}
                            setValue={props.updateValue}
                            placeholder={props.placeholder as string}
                            backgroundColor={
                                props.value === "" || props.value === undefined
                                    ? branding.myProfilePageContent.textFieldsBackgroundColor
                                    : ""
                            }
                            borderAround={"1px solid " + borderColor}
                            outline="1px solid"
                            borderRadius={"5px"}
                        />
                    )}
                    {props.validation && !props.validation(props.value, props.fieldMarker) && props.validationWarning && (
                        <RequiredFieldWarning>{props.validationWarning}</RequiredFieldWarning>
                    )}
                    {props.showRequiredFieldWarning &&
                        isRequiredAndEmpty(props.fieldMarker, props.value, props.required) &&
                        !props.requirementOverrideForPrivatePersons && (
                            <RequiredFieldWarning>{strings.myProfilePageContent.emptyRequiredFieldWarning}</RequiredFieldWarning>
                        )}
                </EditProfileInformationField>
            </Col>
        )
    }
)

interface SocialMediaProps {
    labelText: string
    type: SocialMediaType
    value: string
    updateValue: (value: string) => void
    icon: any
    fieldMarker: Field
    baseLink: SocialMediaBaseLink
    mobile?: boolean
}

const SocialMediaItemContainer = styled.div`
    display: inline-block;
    width: 20px;
    margin-right: 20px;

    &.grayscale {
        filter: grayscale(100%);
    }
`

export const SocialMediaItem: React.FunctionComponent<SocialMediaProps> = (props: SocialMediaProps) => {
    const languageState = useLanguageState()
    const [strings, setStrings] = useState(useLanguageState().getStrings())
    const lang = useLanguageState().getLanguage()

    const required = branding.myProfilePageContent.editRequiredFields.includes(props.fieldMarker)
    const [className, setClassName] = useState<string>("")

    const [isFocused, setIsFocused] = useState<boolean>(false)

    useEffect(() => {
        setStrings(languageState.getStrings())
    }, [lang]) // eslint-disable-line

    const isCleanUsername: boolean =
        isFocused &&
        props.value.length > 0 &&
        !(props.value.includes("/") || props.value.includes("http://") || props.value.includes("https://"))

    useEffect(() => {
        if (
            isFocused ||
            props.value.length === 0 ||
            isSocialMediaLinkCorrect(props.value, props.type) ||
            props.value.includes("/") ||
            props.value.includes("http://") ||
            props.value.includes("https://")
        ) {
            return
        } else {
            props.updateValue(props.baseLink + props.value)
        }
        // eslint-disable-next-line
    }, [isFocused])

    useEffect(() => {
        if (
            !isCleanUsername &&
            ((props.value.length > 0 && !isSocialMediaLinkCorrect(props.value, props.type)) ||
                isRequiredAndEmpty(props.fieldMarker, props.value))
        ) {
            setClassName("editProfileValidationError")
        } else {
            setClassName("")
        }
        // eslint-disable-next-line
    }, [props.fieldMarker, props.value])

    return (
        <Col xs={12} md={6}>
            <EditProfileInformationField>
                {props.mobile ? null : (
                    <EditProfileLabel>
                        <div>{props.labelText} </div>
                        <RequiredFieldLabel visible={required}>*</RequiredFieldLabel>
                    </EditProfileLabel>
                )}
                <SocialMediaItemContainer className={isEmpty(props.value) && !isFocused ? "grayscale" : ""}>
                    {props.icon}
                </SocialMediaItemContainer>
                <div style={{ display: "inline-block", width: "calc(100% - 45px)" }}>
                    <TextField
                        id={props.fieldMarker as string}
                        disabled={branding.myProfilePageContent.editDisabledSocialMedia.includes(props.type)}
                        width="100%"
                        height="30px"
                        fontSize="14px"
                        fontFamily={branding.mainInfoColor}
                        value={props.value}
                        borderColor={
                            isSocialMediaLinkCorrect(props.value, props.type) || props.value.length === 0 || isCleanUsername
                                ? branding.myProfilePageContent.textFieldsBorderColor
                                : branding.dangerButtonColor
                        }
                        setValue={props.updateValue}
                        onFocusToggle={setIsFocused}
                        placeholder={strings.myProfilePageContent.socialMediaLinkPlaceholder}
                        placeholderFontSize="13.5px"
                        isSearchBar={false}
                        isEditProfile
                        className={className}
                    />
                </div>
                {props.value.length > 0 && !isCleanUsername && !isSocialMediaLinkCorrect(props.value, props.type) && (
                    <RequiredFieldWarning>{strings.myProfilePageContent.socialMediaLinkInvalidWarning}</RequiredFieldWarning>
                )}
            </EditProfileInformationField>
        </Col>
    )
}

const StyledSelect = styled(Select)`
    width: 100%;
    font-family: ${branding.font1};
    color: ${branding.mainInfoColor};
    font-size: 14px;
    margin-top: 8px;
    height: 35px;
    /* z-index: 100; // causing problem with dropdowns overlay */
`

const IconCloseWrapper = styled.span`
    margin-left: 10px;
    float: right;
`

const valueContainerStyling = (styles: any) => {
    return {
        ...styles,
        maxHeight: "60px",
        overflow: "auto",
        padding: "2px 15px"
    }
}

interface SalutationSwitcherProps {
    user: User
    updateValues: (user: User) => void
    showRequiredFieldWarning?: boolean //this property is here because required field warnings were not in the design for user registration page
}

export const SalutationSwitcher: React.FunctionComponent<SalutationSwitcherProps> = React.memo(
    (props: SalutationSwitcherProps) => {
        const strings = useLanguageState().getStrings()
        const lang = useLanguageState().getLanguage()

        const salutations: Salutation[] = branding.myProfilePageContent.salutationsList

        const salutationsFiltered = salutations.map((c) => {
            return { label: lang === "de" ? c.salutationDe : c.salutation, value: c.id }
        })
        const [selectedSalutation, setSelectedSalutation] = useState<number | null>(null)

        const required = branding.myProfilePageContent.editRequiredFields.includes(Field.SALUTATION)

        const [borderColor, setBorderColor] = useState(branding.myProfilePageContent.textFieldsBorderColor)

        function getSelectedSalutationLabel(): Salutation | undefined {
            if (selectedSalutation !== null) {
                return salutations[selectedSalutation]
            }

            return undefined
        }

        useEffect(() => {
            if (
                (props.user?.salutation && props.user?.salutation?.length! > 0) ||
                (props.user?.salutationDe && props.user?.salutationDe?.length! > 0)
            ) {
                setSelectedSalutation(
                    salutations.findIndex(
                        (item) =>
                            item.salutation === (props.user?.salutation || "") ||
                            item.salutationDe === (props.user?.salutationDe || "")
                    )
                )
            }
            // eslint-disable-next-line
        }, [])

        useEffect(() => {
            if (selectedSalutation !== null) {
                props.updateValues({
                    ...props.user!,
                    salutation: getSelectedSalutationLabel()?.salutation!,
                    salutationDe: getSelectedSalutationLabel()?.salutationDe!
                })
            } else {
                props.updateValues({ ...props.user!, salutation: "", salutationDe: "" })
            }
            // eslint-disable-next-line
        }, [selectedSalutation])

        useEffect(() => {
            setBorderColor(
                isRequiredAndEmpty(Field.SALUTATION, props.user?.salutation || "") ||
                    isRequiredAndEmpty(Field.SALUTATION, props.user?.salutationDe || "")
                    ? branding.dangerButtonColor
                    : branding.myProfilePageContent.textFieldsBorderColor
            )
            // eslint-disable-next-line
        }, [props.user])

        return (
            <Col xs={12} md={6}>
                <EditProfileInformationField>
                    <EditProfileLabel>
                        <div>{strings.myProfilePageContent.salutationLabel}</div>
                        <RequiredFieldLabel visible={required}>*</RequiredFieldLabel>
                    </EditProfileLabel>
                    <StyledSelect
                        placeholder={strings.myProfilePageContent.salutationPlaceholder}
                        isMulti={false}
                        isClearable={true}
                        options={salutationsFiltered}
                        value={
                            selectedSalutation !== null
                                ? {
                                      label:
                                          lang === "de"
                                              ? getSelectedSalutationLabel()?.salutationDe!
                                              : getSelectedSalutationLabel()?.salutation!,
                                      value: getSelectedSalutationLabel()?.id!
                                  }
                                : null
                        }
                        onChange={(value: ValueType<OptionTypeBase, boolean>, action: ActionMeta<OptionTypeBase>) => {
                            if (value !== null && action.action === "select-option") {
                                const option = value as OptionTypeBase
                                if (selectedSalutation !== option.value) {
                                    let index = salutations.findIndex((item) => item.id === option.value)
                                    setSelectedSalutation(index)
                                }
                            } else setSelectedSalutation(null)
                        }}
                        theme={SelectThemeCustom}
                        styles={{
                            control: (styles: any) => ({
                                ...styles,
                                maxHeight: "65px",
                                borderColor: borderColor,
                                boxShadow: "none",
                                "&:hover": {
                                    borderColor: borderColor,
                                    boxShadow: "none"
                                },
                                "&:focus": {
                                    borderColor: borderColor,
                                    boxShadow: "none"
                                }
                            }),
                            valueContainer: (styles: any) => ({
                                ...styles,
                                maxHeight: "60px",
                                overflow: "auto",
                                padding: "2px 15px"
                            })
                        }}
                    />
                    {false &&
                        (isRequiredAndEmpty(Field.SALUTATION, props.user?.salutation || "") ||
                            isRequiredAndEmpty(Field.SALUTATION, props.user?.salutationDe || "")) && (
                            <RequiredFieldWarning>{strings.myProfilePageContent.emptyRequiredFieldWarning}</RequiredFieldWarning>
                        )}
                </EditProfileInformationField>
            </Col>
        )
    }
)

interface UserRoleSwitcherProps {
    user: User
    userRoles: SelfDefinedUserRole[]
    updateValues: (user: User) => void
    showRequiredFieldWarning?: boolean //this property is here because required field warnings were not in the design for user registration page
}

export const UserRoleSwitcher: React.FunctionComponent<UserRoleSwitcherProps> = React.memo((props: UserRoleSwitcherProps) => {
    const strings = useLanguageState().getStrings()

    const userRolesFiltered = props.userRoles.map((role) => {
        return { label: role.name, value: role.id }
    })
    const [selectedRole, setSelectedRole] = useState<number | null>(null)

    const required = branding.myProfilePageContent.editRequiredFields.includes(Field.USER_ROLE)

    const [borderColor, setBorderColor] = useState(branding.myProfilePageContent.textFieldsBorderColor)

    function getSelectedUserRole(): SelfDefinedUserRole | undefined {
        if (selectedRole !== null) {
            return props.userRoles[selectedRole]
        }

        return undefined
    }

    useEffect(() => {
        setBorderColor(
            isRequiredAndEmpty(Field.USER_ROLE, props.user?.selfDefinedUserRoleId)
                ? branding.dangerButtonColor
                : branding.myProfilePageContent.textFieldsBorderColor
        )
        // eslint-disable-next-line
    }, [props.user])

    useEffect(() => {
        if (
            props.user?.selfDefinedUserRoleId &&
            props.user?.selfDefinedUserRoleId !== "" &&
            props.userRoles.findIndex((role: SelfDefinedUserRole) => role.id === props.user?.selfDefinedUserRoleId!) > -1
        ) {
            setSelectedRole(
                props.userRoles.findIndex((role: SelfDefinedUserRole) => role.id === props.user?.selfDefinedUserRoleId!)
            )
        }
        // eslint-disable-next-line
    }, [props.userRoles])

    useEffect(() => {
        if (selectedRole !== null) {
            props.updateValues({
                ...props.user!,
                selfDefinedUserRoleId: getSelectedUserRole()?.id!
            })
        } else {
            props.updateValues({ ...props.user!, selfDefinedUserRoleId: undefined })
        }
        // eslint-disable-next-line
    }, [selectedRole])

    return (
        <Col xs={12} md={6}>
            <EditProfileInformationField>
                <EditProfileLabel>
                    <div>{strings.myProfilePageContent.userRoleLabel}</div>
                    <RequiredFieldLabel visible={required}>*</RequiredFieldLabel>
                </EditProfileLabel>
                <StyledSelect
                    placeholder={strings.myProfilePageContent.userRolePlaceholder}
                    isMulti={false}
                    isClearable={true}
                    options={userRolesFiltered}
                    value={
                        selectedRole !== null
                            ? {
                                  label: getSelectedUserRole()?.name!,
                                  value: getSelectedUserRole()?.id!
                              }
                            : null
                    }
                    onChange={(value: ValueType<OptionTypeBase, boolean>, action: ActionMeta<OptionTypeBase>) => {
                        if (value !== null && action.action === "select-option") {
                            const option = value as OptionTypeBase
                            if (selectedRole !== option.value) {
                                let index = props.userRoles.findIndex((item) => item.id === option.value)
                                setSelectedRole(index)
                            }
                        } else setSelectedRole(null)
                    }}
                    theme={SelectThemeCustom}
                    styles={{
                        control: (styles: any) => ({
                            ...styles,
                            maxHeight: "65px",
                            borderColor: borderColor,
                            boxShadow: "none",
                            "&:hover": {
                                borderColor: borderColor,
                                boxShadow: "none"
                            },
                            "&:focus": {
                                borderColor: borderColor,
                                boxShadow: "none"
                            }
                        }),
                        valueContainer: (styles: any) => valueContainerStyling
                    }}
                />
                {false &&
                    (isRequiredAndEmpty(Field.USER_ROLE, props.user?.salutation || "") ||
                        isRequiredAndEmpty(Field.USER_ROLE, props.user?.salutationDe || "")) && (
                        <RequiredFieldWarning>{strings.myProfilePageContent.emptyRequiredFieldWarning}</RequiredFieldWarning>
                    )}
            </EditProfileInformationField>
        </Col>
    )
})

interface CountrySwitcherProps {
    country: string
    setCountry: (value: string) => void
    showRequiredFieldWarning?: boolean //this property is here because required field warnings were not in the design for user registration page
}

export const CountrySwitcher: React.FunctionComponent<CountrySwitcherProps> = React.memo((props: CountrySwitcherProps) => {
    const strings = useLanguageState().getStrings()
    const lang = useLanguageState().getLanguage()
    const countries = getCountries(lang)
    const countriesFiltered = countries.map((c) => {
        return { label: lang === "de" ? c.de : c.en, value: c.code }
    })

    const required = branding.myProfilePageContent.editRequiredFields.includes(Field.COUNTRY)

    const [borderColor, setBorderColor] = useState(branding.myProfilePageContent.textFieldsBorderColor)

    useEffect(() => {
        setBorderColor(
            isRequiredAndEmpty(Field.COUNTRY, props.country)
                ? branding.dangerButtonColor
                : branding.myProfilePageContent.textFieldsBorderColor
        )
        // eslint-disable-next-line
    }, [props.country])

    return (
        <Col xs={12} md={6}>
            <EditProfileInformationField>
                <EditProfileLabel>
                    <div>{strings.myProfilePageContent.countryLabel}</div>{" "}
                    <RequiredFieldLabel visible={required}>*</RequiredFieldLabel>
                </EditProfileLabel>
                <StyledSelect
                    placeholder={strings.myProfilePageContent.countrySwitcherText}
                    isMulti={false}
                    isSearchable={true}
                    isClearable={true}
                    options={countriesFiltered}
                    value={props.country !== "" ? { label: findCountryName(props.country, lang), value: props.country } : null}
                    onChange={(value: ValueType<OptionTypeBase, boolean>, action: ActionMeta<OptionTypeBase>) => {
                        if (value !== null && action.action === "select-option") {
                            const option = value as OptionTypeBase
                            if (props.country !== option.value) props.setCountry(option.value)
                        } else props.setCountry("")
                    }}
                    theme={SelectThemeCustom}
                    styles={{
                        control: (styles: any) => ({
                            ...styles,
                            maxHeight: "65px",
                            borderColor: borderColor,
                            boxShadow: "none",
                            "&:hover": {
                                borderColor: borderColor,
                                boxShadow: "none"
                            },
                            "&:focus": {
                                borderColor: borderColor,
                                boxShadow: "none"
                            }
                        }),
                        valueContainer: (styles: any) => valueContainerStyling
                    }}
                />
                {props.showRequiredFieldWarning && isRequiredAndEmpty(Field.COUNTRY, props.country) && (
                    <RequiredFieldWarning>{strings.myProfilePageContent.emptyRequiredFieldWarning}</RequiredFieldWarning>
                )}
            </EditProfileInformationField>
        </Col>
    )
})

interface LanguageSwitcherProps {
    languages: any[]
    setLanguages: (value: any[]) => void
    showRequiredFieldWarning?: boolean //this property is here because required field warnings were not in the design for user registration page
}

export const LanguageSwitcher: React.FunctionComponent<LanguageSwitcherProps> = React.memo((props: LanguageSwitcherProps) => {
    const strings = useLanguageState().getStrings()
    const lang = useLanguageState().getLanguage()
    const languages = getLanguages(lang)
    const languagesFiltered = languages.map((lng) => {
        return { label: lang === "de" ? lng.de : lng.en, value: lng.code }
    })
    const [selectedLanguages, setSelectedLanguages] = useState<(OptionTypeBase | null)[]>([])

    const required = branding.myProfilePageContent.editRequiredFields.includes(Field.LANGUAGE)

    const [borderColor, setBorderColor] = useState(branding.myProfilePageContent.textFieldsBorderColor)

    useEffect(() => {
        setSelectedLanguages(
            props.languages.map((langCode) => {
                return { label: findLanguageName(langCode, lang), value: langCode }
            })
        )
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setBorderColor(
            isRequiredAndEmpty(Field.LANGUAGE, props.languages)
                ? branding.dangerButtonColor
                : branding.myProfilePageContent.textFieldsBorderColor
        )
        // eslint-disable-next-line
    }, [props.languages])

    useEffect(() => {
        setSelectedLanguages(
            props.languages.map((langCode) => {
                return { label: findLanguageName(langCode, lang), value: langCode }
            })
        )
    }, [lang]) // eslint-disable-line

    return (
        <Col xs={12} md={6}>
            <EditProfileInformationField>
                <EditProfileLabel>
                    <div>{strings.myProfilePageContent.preferredLanguagesLabel}</div>
                    <RequiredFieldLabel visible={required}>*</RequiredFieldLabel>
                </EditProfileLabel>

                <StyledSelect
                    placeholder={strings.myProfilePageContent.languagesSwitcherText}
                    isMulti={true}
                    isSearchable={true}
                    isClearable={true}
                    options={selectedLanguages.length >= 15 ? [] : languagesFiltered}
                    value={selectedLanguages}
                    noOptionsMessage={() => branding.myProfilePageContent.languageSelectNoOptionMessage}
                    onChange={(value: ValueType<OptionTypeBase, boolean>[], action: ActionMeta<OptionTypeBase>) => {
                        if (value !== null) {
                            const newLanguages = value.map((x) => {
                                return (x as OptionTypeBase).value
                            })
                            setSelectedLanguages(value)
                            props.setLanguages(newLanguages)
                        }
                    }}
                    theme={SelectThemeCustom}
                    styles={{
                        control: (styles: any) => ({
                            ...styles,
                            maxHeight: "65px",
                            borderColor: borderColor,
                            boxShadow: "none",
                            "&:hover": {
                                borderColor: borderColor,
                                boxShadow: "none"
                            },
                            "&:focus": {
                                borderColor: borderColor,
                                boxShadow: "none"
                            }
                        }),
                        valueContainer: (styles: any) => valueContainerStyling(styles)
                    }}
                />
                {props.showRequiredFieldWarning && isRequiredAndEmpty(Field.LANGUAGE, props.languages) && (
                    <RequiredFieldWarning>{strings.myProfilePageContent.emptyRequiredFieldWarning}</RequiredFieldWarning>
                )}
            </EditProfileInformationField>
        </Col>
    )
})

export const TimeZoneSwitcher: React.FunctionComponent = React.memo(() => {
    const appState = useAppState()
    const strings = useLanguageState().getStrings()
    const timezones = getTimezones()
    const timezonesCustom = timezones.map((t) => {
        return { label: t.name.slice(0, t.name.indexOf(" - ") || 150), value: t.timezones[0] }
    })
    const [selectedTimezone, setSelectedTimezone] = useState<string>(appState.timezone ?? moment.tz.guess())
    const timezoneNameFull = findTimezoneName(selectedTimezone)
    const timezoneName = timezoneNameFull.slice(0, timezoneNameFull.indexOf(" - ") || 150)
    const selectedTimezoneCustom = { label: timezoneName, value: selectedTimezone }

    const borderColor = branding.myProfilePageContent.textFieldsBorderColor

    function onTimezoneSelect(timezone: string) {
        setSelectedTimezone(timezone)
        appState.setTimeZone(timezone)
    }

    return (
        <Col xs={12} md={6}>
            <EditProfileInformationField>
                <EditProfileLabel>{strings.myProfilePageContent.timeZoneLabel}</EditProfileLabel>

                <StyledSelect
                    placeholder={timezoneName}
                    isMulti={false}
                    isSearchable={true}
                    options={timezonesCustom}
                    value={selectedTimezoneCustom}
                    onChange={(value: ValueType<OptionTypeBase, boolean>, action: ActionMeta<OptionTypeBase>) => {
                        if (value !== null && action.action === "select-option") {
                            const option = value as OptionTypeBase
                            if (selectedTimezone !== option.value) onTimezoneSelect(option.value)
                        }
                    }}
                    theme={SelectThemeCustom}
                    styles={{
                        control: (styles: any) => ({
                            ...styles,
                            maxHeight: "65px",
                            borderColor: borderColor,
                            boxShadow: "none",
                            "&:hover": {
                                borderColor: borderColor,
                                boxShadow: "none"
                            },
                            "&:focus": {
                                borderColor: borderColor,
                                boxShadow: "none"
                            }
                        }),
                        valueContainer: (styles: any) => valueContainerStyling(styles)
                    }}
                />
            </EditProfileInformationField>
        </Col>
    )
})

interface SpecialSearchBarProps {
    title: string
    searchParam: string
    setSearchParam: (value: string) => void
    dropdownSearch?: boolean
    dropdownOnClick?: () => void
}

const SpecialSearchBar: React.FunctionComponent<SpecialSearchBarProps> = React.memo((props: SpecialSearchBarProps) => {
    const [iconColor, setIconColor] = useState<string>("gray")

    return (
        <div
            onMouseEnter={() => setIconColor(branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor)}
            onMouseLeave={() => {
                if (props.searchParam.length <= 0) setIconColor("gray")
            }}
        >
            <TextField
                placeholder={props.title}
                value={props.searchParam}
                width="100%"
                fontSize="14px"
                height="35px"
                paddingLeft="8px"
                setValue={props.setSearchParam}
                backgroundColor={branding.myProfilePageContent.textFieldsBackgroundColor}
                borderAround={"1px solid " + branding.myProfilePageContent.textFieldsBorderColor}
                outline="1px solid"
                startAdornment={
                    <div style={{ marginBottom: "5px" }}>
                        {!props.dropdownSearch && IconSearchSmall({ width: "20", height: "20", fill: iconColor })}
                    </div>
                }
                borderRadius={"5px"}
            />
        </div>
    )
})

export function getCategorySectionTitle(strings: any, type: CategoryType) {
    switch (type) {
        case CategoryType.INTERESTS:
            return strings.interestsLabel

        case CategoryType.OFFERS:
            return strings.imOfferingLabel

        case CategoryType.NEEDS:
            return strings.imLookingToBuyLabel

        default:
            return strings.interestsLabel
    }
}

export interface ProfileCategoriesProps {
    type: CategoryType
    data: CategoryData
    setCategoryBindings: (categories: Category[]) => void
    limitNumberOfSelectedCategories?: boolean
    required?: boolean

    setSectionTitle?: (title: string) => void
}

export const ProfileCategories: React.FunctionComponent<ProfileCategoriesProps> = React.memo((props: ProfileCategoriesProps) => {
    const langState = useLanguageState()
    const strings = langState.getStrings().myProfilePageContent

    const [searchParam, setSearchParam] = useState<string>("")

    let availableCategoriesToShow: Category[]
    let workingDataSet: Category[] = []
    let selectedDataSet: Category[] = []

    useEffect(() => {
        if (props.setSectionTitle) {
            props.setSectionTitle(
                getCategorySectionTitle(strings, props.type) +
                    (props.limitNumberOfSelectedCategories
                        ? " (" + (selectedDataSet.length || 0) + "/" + getLimitNumber() + ")"
                        : "")
            )
        }
        // eslint-disable-next-line
    }, [selectedDataSet])

    function addToMyCategories(selectedDataSet: Category[], category: Category) {
        selectedDataSet.push(category)
        props.setCategoryBindings(selectedDataSet)
    }

    function removeFromMyCategories(selectedDataSet: Category[], category: Category) {
        selectedDataSet = selectedDataSet.filter((i) => i !== category)
        props.setCategoryBindings(selectedDataSet)
    }

    function getLimitNumber(): number {
        if (props.type === CategoryType.OFFERS) {
            return branding.myProfilePageContent.numberOfOffers
        } else if (props.type === CategoryType.NEEDS) {
            return branding.myProfilePageContent.numberOfNeeds
        } else {
            return 5
        }
    }

    switch (props.type) {
        case CategoryType.INTERESTS:
            workingDataSet = props.data.interests
            selectedDataSet = props.data.selectedInterests
            break
        case CategoryType.OFFERS:
            workingDataSet = props.data.offerings
            selectedDataSet = props.data.selectedOfferings
            break
        case CategoryType.NEEDS:
            workingDataSet = props.data.lookingfor
            selectedDataSet = props.data.selectedLookingfor
            break
    }
    workingDataSet = workingDataSet.filter((i) => !selectedDataSet.includes(i))

    availableCategoriesToShow = workingDataSet.filter((i: any) => i.name.toLowerCase().includes(searchParam.toLowerCase()))

    return (
        <Row className="ml-20">
            <Col xs={24} md={12}>
                {(workingDataSet.length > 0 || selectedDataSet.length > 0) && (
                    <>
                        <div>
                            <CategoriesViewRow key="selected-categories" style={{ marginLeft: "-20px" }}>
                                <Col xs={24} md={12}>
                                    {selectedDataSet.map((category, key) => {
                                        return (
                                            <React.Fragment key={key}>
                                                <CategoriesItem
                                                    textColor={
                                                        branding.categoriesPageContent.categoriesTextColorOnEditProfilePage
                                                    }
                                                    style={{
                                                        backgroundColor: selectedDataSet
                                                            ? branding.categoriesPageContent
                                                                  .categoriesBackgroundColorOnEditProfilePage
                                                            : "#c9c9c9",
                                                        cursor: "pointer"
                                                    }}
                                                    key={key}
                                                    onClick={() => removeFromMyCategories(selectedDataSet, category)}
                                                >
                                                    {category.name}{" "}
                                                    <IconCloseWrapper>
                                                        {IconCloseNotification({
                                                            fill: branding.categoriesPageContent
                                                                .categoriesIconColorOnEditProfilePage,
                                                            width: "10",
                                                            height: "10"
                                                        })}
                                                    </IconCloseWrapper>
                                                </CategoriesItem>
                                            </React.Fragment>
                                        )
                                    })}
                                </Col>
                            </CategoriesViewRow>

                            <Row key="search">
                                <Col xs={24} md={12}>
                                    <div style={{ marginTop: "15px" }}>
                                        <SpecialSearchBar
                                            searchParam={searchParam}
                                            setSearchParam={setSearchParam}
                                            title={strings.searchPlaceholder}
                                        />
                                    </div>
                                </Col>
                            </Row>

                            {props.type === CategoryType.INTERESTS && (
                                <CategoriesViewRow key="available-categories">
                                    <ProfileCategoriesList
                                        items={availableCategoriesToShow}
                                        searchParam={searchParam}
                                        isNumberLimited={props.limitNumberOfSelectedCategories ?? false}
                                        limitNumber={getLimitNumber()}
                                        selectedCategoriesNumber={selectedDataSet.length}
                                        addToMyCategories={(category) => addToMyCategories(selectedDataSet, category)}
                                        strings={strings}
                                    />
                                </CategoriesViewRow>
                            )}

                            {(props.type === CategoryType.OFFERS || props.type === CategoryType.NEEDS) && (
                                <CategoriesViewRow key="available-categories">
                                    {branding.myProfilePageContent.offersNeedsCategories.length > 0 &&
                                        branding.myProfilePageContent.offersNeedsCategories
                                            .filter((x) => x.id !== "default")
                                            .map((parentCategory, key) => {
                                                let parentCategoryItems = availableCategoriesToShow.filter(
                                                    (i: any) => i.parent === parentCategory.id
                                                )
                                                return (
                                                    <>
                                                        {parentCategoryItems.length > 0 && (
                                                            <Col md={12} style={{ padding: "3px", margin: "0" }}>
                                                                <LabelText className="parentCategory">
                                                                    {parentCategory.name}
                                                                </LabelText>

                                                                <ProfileCategoriesList
                                                                    items={parentCategoryItems}
                                                                    searchParam={searchParam}
                                                                    isNumberLimited={
                                                                        props.limitNumberOfSelectedCategories ?? false
                                                                    }
                                                                    limitNumber={getLimitNumber()}
                                                                    selectedCategoriesNumber={selectedDataSet.length}
                                                                    addToMyCategories={(category) =>
                                                                        addToMyCategories(selectedDataSet, category)
                                                                    }
                                                                    strings={strings}
                                                                />
                                                            </Col>
                                                        )}
                                                    </>
                                                )
                                            })}
                                    {branding.myProfilePageContent.offersNeedsCategories.length === 0 && (
                                        <CategoriesViewRow key="available-categories" style={{ marginLeft: "-20px" }}>
                                            <ProfileCategoriesList
                                                items={availableCategoriesToShow}
                                                searchParam={searchParam}
                                                isNumberLimited={props.limitNumberOfSelectedCategories ?? false}
                                                limitNumber={getLimitNumber()}
                                                selectedCategoriesNumber={selectedDataSet.length}
                                                addToMyCategories={(category) => addToMyCategories(selectedDataSet, category)}
                                                strings={strings}
                                            />
                                        </CategoriesViewRow>
                                    )}
                                </CategoriesViewRow>
                            )}
                        </div>
                    </>
                )}
            </Col>
        </Row>
    )
})

export interface ProfileCategoriesListProps {
    items: any[]
    searchParam: string
    strings: any
    isNumberLimited: boolean
    limitNumber: number
    selectedCategoriesNumber: number
    addToMyCategories: (item: any) => void
}

const ProfileCategoriesList: React.FunctionComponent<ProfileCategoriesListProps> = React.memo(
    (props: ProfileCategoriesListProps) => {
        const [showMore, setShowMore] = useState<boolean>(false)

        return (
            <Col xs={24} md={12} style={{ paddingLeft: "0" }}>
                {(showMore || props.searchParam.length > 0
                    ? props.items
                    : props.items.slice(0, branding.myProfilePageContent.numberOfCategories)
                ).map((category, key) => {
                    return (
                        <CategoriesItem
                            key={key}
                            border="1px solid #727272"
                            textColor="#727272"
                            onClick={() => props.addToMyCategories(category)}
                            style={{
                                pointerEvents:
                                    props.isNumberLimited && props.limitNumber <= props.selectedCategoriesNumber
                                        ? "none"
                                        : "auto",
                                cursor:
                                    props.isNumberLimited && props.limitNumber <= props.selectedCategoriesNumber
                                        ? "not-allowed"
                                        : "pointer"
                            }}
                        >
                            {category.name}
                        </CategoriesItem>
                    )
                })}
                {!showMore &&
                    props.searchParam.length === 0 &&
                    props.items.length > branding.myProfilePageContent.numberOfCategories && (
                        //<WhiteButton onClick={() => setShowMore(true)}>{props.strings.showMoreButtonText}</WhiteButton>

                        <ShowMoreButton onClick={() => setShowMore(true)}>{props.strings.showMoreButtonText}</ShowMoreButton>
                    )}
                {showMore && props.searchParam.length === 0 && (
                    //<WhiteButton onClick={() => setShowMore(false)}>{props.strings.showLessButtonText}</WhiteButton>

                    <ShowMoreButton active={true} onClick={() => setShowMore(false)}>
                        {props.strings.showLessButtonText}
                    </ShowMoreButton>
                )}
            </Col>
        )
    }
)

const ShowMoreButton = styled.div<{ active?: boolean }>`
    display: inline-block;
    background-color: ${(props) => (props.active ? "#fff" : branding.crsTabs.defaultActionItemActiveStateColor)};
    border: 1px solid ${branding.crsTabs.defaultActionItemActiveStateColor};
    border-radius: 5px;
    padding: 8px 10px;
    color: ${(props) => (props.active ? branding.crsTabs.defaultActionItemActiveStateColor : "#fff")};
    cursor: pointer;
    font-size: 14px;
    margin: 5px;
    transition: 500ms;

    &:hover {
        opacity: 0.7;
    }

    @media ${device.mobile} {
        font-size: 12px;
    }
`

export const SelectThemeCustom = (theme: any) => ({
    ...theme,
    width: "290px",
    colors: {
        ...theme.colors,
        primary: branding.dropdownStyle.primaryColor,
        primary25: branding.dropdownStyle.primary25Color,
        primary50: branding.dropdownStyle.primary50Color,
        primary75: branding.dropdownStyle.primary75Color
    }
})

export function DeleteProfile() {
    const [showDeleteProfileWarning, setShowDeleteProfileWarning] = useState<boolean>(false)
    const [showDeleteProfileConfirmation, setShowDeleteProfileConfirmation] = useState<boolean>(false)
    const userState = useLoggedInState()
    const strings = useLanguageState().getStrings()

    async function deleteProfileAction(userId: string | undefined, userPool: string) {
        await deleteUserProfile(userId, userPool)
    }

    return (
        <>
            {showDeleteProfileWarning && (
                <ConfirmationModal
                    dialogWidth="390px"
                    title={strings.myProfilePageContent.deleteProfileModalTitle}
                    text={strings.myProfilePageContent.deleteProfileModalText}
                    acceptButtonText={strings.myProfilePageContent.deleteProfileModalContinueButton}
                    dismissButtonText={strings.myProfilePageContent.deleteProfileModalCancelButton}
                    accept={() => {
                        setShowDeleteProfileWarning(false)
                        setShowDeleteProfileConfirmation(true)
                        deleteProfileAction(userState.user()?.profileId, branding.configuration.userPoolName)
                    }}
                    dismiss={() => setShowDeleteProfileWarning(false)}
                />
            )}

            {showDeleteProfileConfirmation && (
                <ConfirmationModal
                    dialogWidth="390px"
                    title={strings.myProfilePageContent.deleteProfileConfirmModalTitle}
                    text={strings.myProfilePageContent.deleteProfileConfirmModalText}
                    acceptButtonText={strings.myProfilePageContent.deleteProfileConfirmModalSubmit}
                    accept={() => {
                        setShowDeleteProfileConfirmation(false)
                    }}
                    dismiss={() => setShowDeleteProfileConfirmation(false)}
                    dismissButtonText={""}
                    hideDisagreeButton={true}
                />
            )}

            <DeleteProfileLink onClick={() => setShowDeleteProfileWarning(true)}>
                {strings.myProfilePageContent.deleteProfileLinkLabel}
            </DeleteProfileLink>
        </>
    )
}
