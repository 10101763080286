import { ReactElement, useCallback, useMemo } from "react"
import * as React from "react"
import { ReactSVG } from "react-svg"
import { defaultLogger as logger } from "../globalStates/AppState"
import CenteredLoader from "./CenteredLoader"
import branding from "../branding/branding"

export interface IconProps {
    stroke?: string
    width?: string
    height?: string
    fill?: string
    viewBox?: string
    style?: string
}

export type VGIcon = ReactElement | null

export const Icon: React.FC<{ name: string; props?: IconProps }> = (propsAttr): VGIcon => (
    <IconWithUrl url={`/branding/icons/${propsAttr.name}.svg`} {...propsAttr} />
)

export const IconWithUrl: React.FC<{ url: string; props?: IconProps }> = (propsAttr): VGIcon => {
    const { url, props } = propsAttr
    const propsString = JSON.stringify(props)
    let svg = null

    const replaceProps = (str: string, props: IconProps) => {
        for (let [key, value] of Object.entries(props!)) {
            if (!value) {
                continue
            }
            str = str.replace(new RegExp(`\\{${key}\\}`, "gi"), value)
        }
        return str
    }

    const beforeInjection = useCallback(
        (svg: SVGElement) => {
            if (!props) return

            const newAttributes = []
            for (let i = 0; i < svg.attributes.length; i++) {
                const attr = svg.attributes[i]
                newAttributes.push({ name: attr.name, value: replaceProps(attr.value, props) })
            }
            for (let i = 0; i < newAttributes.length; i++) {
                const attr = newAttributes[i]
                svg.setAttribute(attr.name, attr.value)
            }
            svg.innerHTML = replaceProps(svg.innerHTML, props)
        },
        [props]
    )

    svg = useMemo(() => {
        return (
            <ReactSVG
                className="svg-icons-wrapper"
                src={url}
                wrapper="span"
                beforeInjection={beforeInjection}
                afterInjection={(err, _) => {
                    if (err) {
                        logger.error({
                            message: `could not load svg '${url}', for topic ${branding.configuration.topicName}`,
                            err
                        })
                    }
                }}
                loading={() => <CenteredLoader size="sm" />}
            />
        )
    }, [propsString, url]) // eslint-disable-line

    if (url === undefined) return null

    return svg
}
// sorted alphabetically as in Icons folder
export const IconAnswerOptionSingleFull: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "#6dd400"
    return <Icon name="answer_option_single_full" props={props} />
}

export const IconAnswerOptionSingleEmpty: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="answer_option_single_empty" props={props} />
}

export const IconAnswerOptionMultipleFull: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "#6dd400"
    return <Icon name="answer_option_multiple_full" props={props} />
}

export const IconAnswerOptionMultipleEmpty: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="answer_option_multiple_empty" props={props} />
}

export const IconArrowBreadcrumb: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="arrow_breadcrumb" props={props} />
}

export const IconArrowDownTimezoneDropdown: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="arrow_down_timezoneDropdown" props={props} />
}

export const IconArrowUpTimezoneDropdown: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="arrow_up_timezoneDropdown" props={props} />
}

export const IconArrowDown: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.stroke = props.stroke ?? "#fff"
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="arrow_down" props={props} />
}

export const IconArrowHeadDown: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="arrow_head_down" props={props} />
}

export const IconArrowHeadRight: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="arrow_head_right" props={props} />
}

export const IconArrowHeadUp: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="arrow_head_up" props={props} />
}

export const IconArrowLeftCarousel: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    props.fill = props.fill ?? "currentColor"
    return <Icon name="arrow_left_carousel" props={props} />
}

export const IconArrowLeft: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="arrow_left" props={props} />
}

export const IconArrowRightCarousel: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="arrow_right_carousel" props={props} />
}

export const IconArrowRight: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="arrow_right" props={props} />
}

export const IconBackofficeCheckbox: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="backoffice_checkbox" props={props} />
}

export const IconBackofficeEditCompanyProfile: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    props.fill = props.fill ?? "currentColor"
    return <Icon name="backoffice_editcompanyprofile" props={props} />
}

export const IconBookmarkFilled: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="bookmark_filled" props={props} />
}

export const IconBookmark: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    props.fill = props.fill ?? ""
    return <Icon name="bookmark" props={props} />
}

export const IconBusinessArea: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="business_area" props={props} />
}

export const IconCalendarEntryDownload: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.stroke = props.stroke ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="calendarentry_download" props={props} />
}

export const IconAcceptCall: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.stroke = props.stroke ?? "currentColor"
    return <Icon name="call_accept" props={props} />
}

export const IconEndCall: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.stroke = props.stroke ?? "currentColor"
    return <Icon name="call_end" props={props} />
}

export const IconRemoveFromCall: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.stroke = props.stroke ?? "currentColor"
    return <Icon name="call_remove" props={props} />
}

export const IconCameraOff: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "20px"
    props.height = props.height ?? "20px"
    props.fill = props.fill ?? "currentColor"
    return <Icon name="camera_off" props={props} />
}

export const IconCardView: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="card_view" props={props} />
}

export const IconCCChats: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="cc_chats" props={props} />
}

export const IconCCNetworking: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="cc_networking" props={props} />
}

export const IconCCNotifications: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="cc_notifications" props={props} />
}

export const IconCCSchedule: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="cc_schedule" props={props} />
}

export const IconMuteChat: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="chat_mute" props={props} />
}

export const IconRemoveFromChat: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.stroke = props.stroke ?? "currentColor"
    return <Icon name="chat_remove" props={props} />
}

export const IconStartChat: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="chat_start" props={props} />
}

export const IconUnMuteChat: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="chat_unmute" props={props} />
}

export const IconCheckboxEmpty: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.stroke = props.stroke ?? "currentColor"
    props.fill = props.fill ?? "currentColor"
    return <Icon name="checkbox_empty" props={props} />
}

export const IconCheckbox: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="checkbox" props={props} />
}

export const IconCheckmark: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    props.fill = props.fill ?? "currentColor"
    return <Icon name="checkmark" props={props} />
}

export const IconChevronLeft: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "20px"
    props.height = props.height ?? "20px"
    props.fill = props.fill ?? "currentColor"
    return <Icon name="chevron_left" props={props} />
}

export const IconArrowRightChevron: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="chevron_right" props={props} />
}

export const IconChevronRight: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "20px"
    props.height = props.height ?? "20px"
    props.fill = props.fill ?? "currentColor"
    return <Icon name="chevron_right" props={props} />
}

export const IconChevronLeftSolid: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="chevron-left-solid" props={props} />
}

export const IconChevronRightSolid: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="chevron-right-solid" props={props} />
}

export const IconCloseBanner: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="close_banner" props={props} />
}

export const IconCloseNotification: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "15"
    props.height = props.height ?? "15"
    return <Icon name="close_notification" props={props} />
}

export const IconCloseParticipants: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    props.fill = props.fill ?? "currentColor"
    return <Icon name="close_participants" props={props} />
}

export const IconCloseRed: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="close_red" props={props} />
}

export const IconClose: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="close" props={props} />
}

export const IconCloseV2: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="closeV2" props={props} />
}

export const IconCollapse: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    props.fill = props.fill ?? "currentColor"
    return <Icon name="collapse" props={props} />
}

export const IconCollections: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.stroke = props.stroke ?? "currentColor"
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="collections" props={props} />
}

export const IconNetworkingToggleOpen: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="communication_area_toggle_open" props={props} />
}

export const IconNetworkingToggleClose: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="communication_area_toggle_close" props={props} />
}

export const IconConnect: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.stroke = props.stroke ?? "currentColor"
    return <Icon name="connect" props={props} />
}

export const IconConnectWithdraw: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.stroke = props.stroke ?? "currentColor"
    return <Icon name="connect_withdraw" props={props} />
}

export const IconContact: React.FC<IconProps> = (props): VGIcon => {
    props.fill = props.fill ?? "currentColor"
    props.stroke = props.stroke ?? "currentColor"
    props.width = props.width ?? "22"
    props.height = props.height ?? "22"
    return <Icon name="contact" props={props} />
}

export const IconCoupon: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="coupons" props={props} />
}

export const IconDecline: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.stroke = props.stroke ?? "currentColor"
    return <Icon name="decline" props={props} />
}

export const IconDefaultAvatar: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    props.fill = props.fill ?? "currentColor"
    return <Icon name="default_avatar" props={props} />
}

export const IconUnfocus: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    props.fill = props.fill ?? "currentColor"
    return <Icon name="defocus" props={props} />
}

export const IconDoNotDisturb: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    props.fill = props.fill ?? "currentColor"
    return <Icon name="do_not_disturb" props={props} />
}

export const IconEdit: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="edit" props={props} />
}

export const IconEditCompanyProfile: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    props.fill = props.fill ?? "currentColor"
    return <Icon name="editcompanyprofile" props={props} />
}

export const IconEmail: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    props.fill = props.fill ?? "currentColor"
    return <Icon name="email" props={props} />
}

export const IconExpand: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    props.fill = props.fill ?? "black"
    return <Icon name="expand" props={props} />
}

export const IconFavoriteFilled: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="favorite_filled" props={props} />
}

export const IconFocus: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    props.fill = props.fill ?? "currentColor"
    return <Icon name="focus" props={props} />
}

export const IconGlobalError: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "40"
    props.height = props.height ?? "40"
    return <Icon name="global_error" props={props} />
}

export const IconHide: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    props.stroke = props.fill ?? "currentColor"
    return <Icon name="hide" props={props} />
}

export const IconHome: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="home" props={props} />
}

export const IconArrowDownSettings: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "14"
    props.height = props.height ?? "7"
    props.fill = props.fill ?? "currentColor"
    return <Icon name="icon_arrow_down_settings" props={props} />
}

export const IconArrowUpSettings: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "14"
    props.height = props.height ?? "8"
    props.fill = props.fill ?? "currentColor"
    return <Icon name="icon_arrow_up_settings" props={props} />
}

export const IconVCLarge: React.FC<IconProps> = (props): VGIcon => {
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "81"
    props.height = props.height ?? "75"
    return <Icon name="icon_virtualcafe_large" props={props} />
}

export const IconIframeTest: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="iframe_test" props={props} />
}

export const IconIgnore: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="ignore" props={props} />
}

export const IconImageUploadPlaceholder: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="image_upload_placeholder" props={props} />
}

export const IconIndicatorErrorBlack: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="indicator_error" props={props} />
}

export const IconInfo: React.FC<IconProps> = (props): VGIcon => {
    props.fill = props.fill ?? "currentColor"
    props.stroke = props.stroke ?? "currentColor"
    props.width = props.width ?? "22"
    props.height = props.height ?? "22"
    return <Icon name="info" props={props} />
}

export const IconJoboffer: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="joboffer" props={props} />
}

export const IconJobofferStart: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="joboffer_start" props={props} />
}

export const IconJobofferEmploymentType: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="joboffer_employment_type" props={props} />
}

export const IconJobofferHomeOffice: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="joboffer_homeoffice" props={props} />
}

export const IconLinkRightArrow: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.stroke = props.stroke ?? "currentColor"
    props.fill = props.fill ?? "currentColor"
    return <Icon name="link_right_arrow" props={props} />
}

export const IconListView: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="list_view" props={props} />
}

export const IconLiveStageTag: React.FC<IconProps> = (props): VGIcon => {
    props.fill = props.fill ?? "currentColor"
    props.stroke = props.stroke ?? "currentColor"
    return <Icon name="livestage_tag" props={props} />
}

export const IconLocation: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    props.fill = props.fill ?? "currentColor"
    return <Icon name="location" props={props} />
}

export const IconLock: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "100"
    props.height = props.height ?? "114"
    props.fill = props.fill ?? "#1D1D1B"
    return <Icon name="lock" props={props} />
}

export const IconLogout: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="logout" props={props} />
}

export const IconMagazineFolder: React.FC<IconProps> = (props): VGIcon => {
    props.fill = props.fill ?? "currentColor"
    props.stroke = props.stroke ?? "currentColor"
    props.width = props.width ?? "22"
    props.height = props.height ?? "22"
    return <Icon name="magazine-folder" props={props} />
}

export const IconMasterclassTag: React.FC<IconProps> = (props): VGIcon => {
    props.fill = props.fill ?? "currentColor"
    props.stroke = props.stroke ?? "currentColor"
    return <Icon name="masterclass_tag" props={props} />
}

export const IconMicrophoneMute: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.stroke = props.stroke ?? "currentColor"
    props.fill = props.fill ?? "currentColor"
    return <Icon name="mic_mute" props={props} />
}

export const IconMicrophoneUnmute: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.stroke = props.stroke ?? "currentColor"
    props.fill = props.fill ?? "currentColor"
    return <Icon name="mic_unmute" props={props} />
}

export const IconMinusAlt: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    props.fill = props.fill ?? "black"
    return <Icon name="minus_alt" props={props} />
}

export const IconMinus: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    props.fill = props.fill ?? "black"
    return <Icon name="minus" props={props} />
}

export const IconMobile: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="mobile" props={props} />
}

export const IconMySchedule: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.stroke = props.stroke ?? "#fff"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    props.style = props.style ?? ""
    return <Icon name="my_schedule_list" props={props} />
}

export const IconMyFair: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="my_fair" props={props} />
}

export const IconNavigationHelp: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.stroke = props.stroke ?? "currentColor"
    props.fill = props.fill ?? "currentColor"
    return <Icon name="navihelp" props={props} />
}

export const IconNetworkingMinus: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    props.fill = props.fill ?? "black"
    return <Icon name="networking_minus" props={props} />
}

export const IconNetworkingPlus: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    props.fill = props.fill ?? "black"
    return <Icon name="networking_plus" props={props} />
}

export const IconCheckmarkSuccessfullMini: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    props.fill = props.fill ?? "currentColor"
    return <Icon name="checkmark_successfull_mini" props={props} />
}

export const IconCheckmarkSuccessfull: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="checkmark_successfull" props={props} />
}

export const IconCheckmarkGreen: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="checkmark_green" props={props} />
}

export const IconNetworking: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="networking" props={props} />
}

export const IconNews: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="news" props={props} />
}

export const IconOrganization: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="organizations" props={props} />
}

export const IconPin: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    props.fill = props.fill ?? "currentColor"
    return <Icon name="pin" props={props} />
}

export const IconPlayFilled: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "25"
    props.height = props.height ?? "25"
    props.fill = props.fill ?? "currentColor"
    props.stroke = props.stroke ?? "currentColor"
    return <Icon name="play-filled" props={props} />
}

export const IconPlay: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "150"
    props.height = props.height ?? "169"
    props.fill = props.fill ?? "white"
    props.stroke = props.stroke ?? "currentColor"
    return <Icon name="play" props={props} />
}

export const IconPlusAlt: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    props.fill = props.fill ?? "black"
    return <Icon name="plus_alt" props={props} />
}

export const IconPlus: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    props.fill = props.fill ?? "black"
    return <Icon name="plus" props={props} />
}

export const IconPointerRight: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="pointer_right" props={props} />
}

export const IconPrerecordedTag: React.FC<IconProps> = (props): VGIcon => {
    props.fill = props.fill ?? "currentColor"
    props.stroke = props.stroke ?? "currentColor"
    return <Icon name="prerecorded_tag" props={props} />
}

export const IconPressMedia: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="press_media" props={props} />
}

export const IconProduct: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="product" props={props} />
}

export const IconProgramArrowRight: React.FC<IconProps> = (props): VGIcon => {
    props.fill = props.fill ?? "black"
    props.width = props.width ?? "22"
    props.height = props.height ?? "22"
    props.fill = props.fill ?? "currentColor"
    return <Icon name="program-arrow-right" props={props} />
}

export const IconProgramArrowLeft: React.FC<IconProps> = (props): VGIcon => {
    props.fill = props.fill ?? "black"
    props.width = props.width ?? "22"
    props.height = props.height ?? "22"
    props.fill = props.fill ?? "currentColor"
    return <Icon name="program-arrow-left" props={props} />
}

export const IconProgram: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.stroke = props.stroke ?? "currentColor"
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="program" props={props} />
}

export const IconRaiseHand: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "#727272"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="raise_hand" props={props} />
}

export const IconLowerHand: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="raise_hand_stop" props={props} />
}

export const IconRecommend: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="recommend" props={props} />
}

export const IconRemoteProviderTag: React.FC<IconProps> = (props): VGIcon => {
    props.fill = props.fill ?? "currentColor"
    props.stroke = props.stroke ?? "currentColor"
    return <Icon name="remoteprovider_tag" props={props} />
}

export const IconReplay: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="replay" props={props} />
}

export const IconReport: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.stroke = props.stroke ?? "currentColor"
    return <Icon name="report" props={props} />
}

export const IconReset: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="reset" props={props} />
}

export const IconRoundtableTag: React.FC<IconProps> = (props): VGIcon => {
    props.fill = props.fill ?? "currentColor"
    props.stroke = props.stroke ?? "currentColor"
    return <Icon name="roundtable_tag" props={props} />
}

export const IconPercentage: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "auto"
    props.fill = props.fill ?? "#D9D9D9"
    return <Icon name="registration_percentage" props={props} />
}

export const IconCamera: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "20px"
    props.height = props.height ?? "20px"
    props.fill = props.fill ?? "currentColor"
    return <Icon name="camera" props={props} />
}

export const IconFavorite: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    props.fill = props.fill ?? ""
    props.stroke = props.stroke ?? ""
    return <Icon name="favorite" props={props} />
}

export const IconHallplan: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.stroke = props.stroke ?? "currentColor"
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="hallplan" props={props} />
}

export const IconSchedule: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="schedule" props={props} />
}

export const IconClearSearchItemLarge: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="search_clear_large" props={props} />
}

export const IconClearSearchItem: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="search_clear" props={props} />
}

export const IconSearchSmall: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "15"
    props.height = props.height ?? "15"
    props.fill = props.fill ?? "currentColor"
    return <Icon name="search_small" props={props} />
}

export const IconSearch: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="search" props={props} />
}

export const IconSettings: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="settings" props={props} />
}

export const IconShareLink: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="share_link" props={props} />
}

export const IconShareScreen: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="share_screen" props={props} />
}

export const IconShareScreenStop: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="share_screen_stop" props={props} />
}

export const IconShowMore: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="show_more" props={props} />
}

export const IconShowLess: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="show_less" props={props} />
}

export const IconShow: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    props.stroke = props.fill ?? "currentColor"
    return <Icon name="show" props={props} />
}

export const IconSoundMute: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.stroke = props.stroke ?? "currentColor"
    props.fill = props.fill ?? "currentColor"
    return <Icon name="sound_mute" props={props} />
}

export const IconSoundUnmute: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.stroke = props.stroke ?? "currentColor"
    props.fill = props.fill ?? "currentColor"
    return <Icon name="sound_unmute" props={props} />
}

export const IconSpeakerIndicator: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="speaker_indicator" props={props} />
}

export const IconSpeakers: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="speakers" props={props} />
}

export const IconSponsor: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="sponsors" props={props} />
}

export const IconStaffIndicator: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="staff_indicator" props={props} />
}

export const IconRemoveStaff: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="staff_remove" props={props} />
}

export const IconStaff: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    props.fill = props.fill ?? "currentColor"
    return <Icon name="staff" props={props} />
}

export const IconExhibitorStartup: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="startup" props={props} />
}

export const IconLiveStream: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="stream" props={props} />
}

export const IconArrowDownLangSwitcher: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "10"
    props.height = props.height ?? "7"
    props.fill = props.fill ?? "currentColor"
    return <Icon name="template_arrow_down" props={props} />
}

export const IconArrowUpLangSwitcher: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "10"
    props.height = props.height ?? "7"
    props.fill = props.fill ?? "currentColor"
    return <Icon name="template_arrow_up" props={props} />
}

export const IconBackoffice: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.stroke = props.stroke ?? "currentColor"
    return <Icon name="template_backoffice" props={props} />
}

export const IconBlockContact: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="template_block_contact" props={props} />
}

export const IconBookmarkToggle: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.stroke = props.stroke ?? ""
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    props.fill = props.fill ?? ""
    props.viewBox = props.viewBox && props.viewBox !== "" ? props.viewBox : "0 0 14 20"
    return <Icon name="template_bookmark_toggle" props={props} />
}

export const IconCalendarEntry: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.stroke = props.stroke ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="template_calendar_entry" props={props} />
}

export const IconCameraJoin: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="template_camera_join" props={props} />
}

export const IconCall: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.stroke = props.stroke ?? "currentColor"
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="template_call" props={props} />
}

export const IconChat: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="template_chat" props={props} />
}

export const IconCloseFilled: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.stroke = props.stroke ?? "#FFFFFF"
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "25"
    props.height = props.height ?? "25"
    return <Icon name="template_close_filled" props={props} />
}

export const IconDownloadVCard: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.stroke = props.stroke ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="template_download_vcard" props={props} />
}

export const IconDownload: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.stroke = props.stroke ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="template_download" props={props} />
}

export const IconEmojiDefault: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "black"
    return <Icon name="template_emoji_default" props={props} />
}

export const IconEmojiSelected: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "black"
    return <Icon name="template_emoji_selected" props={props} />
}

export const IconDownloadEventDateDetail: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.stroke = props.stroke ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="template_eventdatedetail_download" props={props} />
}

export const IconFile: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.stroke = props.stroke ?? "currentColor"
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "40"
    props.height = props.height ?? "40"
    return <Icon name="template_file" props={props} />
}

export const IconLanguages: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "19"
    return <Icon name="template_languages" props={props} />
}

export const IconLink: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.stroke = props.stroke ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="template_link" props={props} />
}

export const vgLive: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.stroke = props.stroke ?? "currentColor"
    props.stroke = props.stroke ?? "currentColor"
    return <Icon name="template_live" props={props} />
}

export const IconMeetingSchedule: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.stroke = props.stroke ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="template_meeting_schedule" props={props} />
}

export const IconMenu: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.stroke = props.stroke ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="template_menu" props={props} />
}

export const IconSendMessage: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "black"
    return <Icon name="template_message_send" props={props} />
}

export const IconMicrophone: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.stroke = props.stroke ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="template_microphone" props={props} />
}

export const IconMicrophoneOff: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.stroke = props.stroke ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="template_microphone_off" props={props} />
}

export const IconMyFairBlackWhite: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.stroke = props.stroke ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "21"
    return <Icon name="template_my_fair_black_white" props={props} />
}

export const IconPeople: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    props.fill = props.fill ?? "currentColor"
    return <Icon name="template_people" props={props} />
}

export const IconPhone: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="template_phone" props={props} />
}

export const IconRoundTable: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    props.fill = props.fill ?? "none"
    return <Icon name="template_roundtable" props={props} />
}

export const IconShare: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.stroke = props.stroke ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="template_share" props={props} />
}

export const IconSponsorCustom: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "25"
    props.height = props.height ?? "25"
    return <Icon name="template_sponsor_custom" props={props} />
}

export const IconUserCustom: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "25"
    props.height = props.height ?? "25"
    props.fill = props.fill ?? "none"
    return <Icon name="template_user_custom" props={props} />
}

export const IconMeetings: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.stroke = props.stroke ?? "#FFFFFF"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="template_video_chat" props={props} />
}

export const IconThreeDotsMenu: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="three_dots_menu" props={props} />
}

export const IconThreeDotsMenuVertical: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="three_dots_menu_vertical" props={props} />
}

export const IconTicket: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "100"
    props.height = props.height ?? "114"
    props.fill = props.fill ?? "#1D1D1B"
    return <Icon name="ticket" props={props} />
}

export const IconTimeZone: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="timezone" props={props} />
}

export const IconTrademark: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="trademark" props={props} />
}

export const IconTypeformTag: React.FC<IconProps> = (props): VGIcon => {
    props.fill = props.fill ?? "none"
    props.stroke = props.stroke ?? "currentColor"
    return <Icon name="typeform_tag" props={props} />
}

export const IconUnLockedEvent: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "13"
    props.height = props.height ?? "11"
    props.fill = props.fill ?? "#1D1D1B"
    return <Icon name="unlocked_event" props={props} />
}

export const IconUnpin: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    props.fill = props.fill ?? "currentColor"
    return <Icon name="unpin" props={props} />
}

export const IconVideo: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="video" props={props} />
}

export const IconVideoOnDemandTag: React.FC<IconProps> = (props): VGIcon => {
    props.fill = props.fill ?? "currentColor"
    props.stroke = props.stroke ?? "currentColor"
    return <Icon name="videoondemand_tag" props={props} />
}

export const IconVolumeHigh: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="volume_high" props={props} />
}

export const IconVolumeOff: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    return <Icon name="volume_off" props={props} />
}

// social media icons

export const IconFacebook: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="template_facebook" props={props} />
}

export const IconFacebookOriginal: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="facebook_original" props={props} />
}

export const IconInstagram: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "none"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="template_instagram" props={props} />
}

export const IconInstagramOriginal: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="instagram_original" props={props} />
}

export const IconLinkedIn: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "none"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="template_linked_in" props={props} />
}

export const IconLinkedInOriginal: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="linkedin_original" props={props} />
}

export const IconPinterest: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="template_pinterest" props={props} />
}

export const IconPinterestOrginal: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="pinterest_original" props={props} />
}

export const IconTwitter: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="template_twitter" props={props} />
}

export const IconTwitterOriginal: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="twitter_original" props={props} />
}

export const IconXing: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "none"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="template_xing" props={props} />
}

export const IconXingOriginal: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="xing_original" props={props} />
}

export const IconYouTube: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.fill = props.fill ?? "currentColor"
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="template_youtube" props={props} />
}

export const IconYouTubeOriginal: React.FC<IconProps> = (props): VGIcon => {
    props = { ...props }
    props.width = props.width ?? "20"
    props.height = props.height ?? "20"
    return <Icon name="youtube_original" props={props} />
}

// end of social media icons
